<template>
  <v-container>
    <v-row>
      <v-col cols="5">
        <v-img
          :src="
            defaultImage(
              cast.thumbnailUrl,
              cast.shop_default_thumbnail_set.set_name
            )
          "
          v-on:error="cast.thumbnailUrl = noimage_m"
          :max-height="imageHeight"
          contain
        >
          <v-row align="end" class="cast-list__text ma-0">
            <v-col>
              <p class="ma-0">
                {{
                  this.isPrivateFlag || this.cast_free
                    ? $t('free')
                    : cast.site_cast_name
                }}
              </p>
            </v-col>
          </v-row>
        </v-img>
      </v-col>

      <v-col cols="7" class="usr-call-reserve__detail-header">
        <v-row class="my-1">
          <v-col cols="8" class="pa-0">{{ $t('course') }}</v-col
          ><v-col cols="4" class="py-0 pl-0 text-right">{{
            course_money.toLocaleString()
          }}</v-col>
        </v-row>
        <v-row v-if="before_discount_designation_money" class="my-1">
          <v-col cols="8" class="pa-0">{{ $t('designated_fee') }}</v-col
          ><v-col cols="4" class="py-0 pl-0 text-right">{{
            designationMoneyAddCastAdditionalMoney.toLocaleString()
          }}</v-col>
          <v-col cols="8" class="pa-0">{{ $t('special_card') }}</v-col
          ><v-col cols="4" class="py-0 pl-0 text-right"
            >-{{ before_discount_designation_money.toLocaleString() }}</v-col
          >
        </v-row>
        <v-row
          v-else-if="
            !before_discount_designation_money &&
              designationMoneyAddCastAdditionalMoney > Number(0)
          "
          class="my-1"
        >
          <v-col cols="8" class="pa-0">{{ $t('designated_fee') }}</v-col
          ><v-col cols="4" class="py-0 pl-0 text-right">{{
            designationMoneyAddCastAdditionalMoney.toLocaleString()
          }}</v-col>
        </v-row>
        <v-row v-if="option_money > Number(0)" class="my-1">
          <v-col cols="8" class="pa-0">{{ $t('option') }}</v-col
          ><v-col cols="4" class="py-0 pl-0 text-right">{{
            option_money.toLocaleString()
          }}</v-col>
        </v-row>
        <v-row class="my-1">
          <v-col cols="8" class="pa-0">{{
            $t('transportation_expenses')
          }}</v-col
          ><v-col cols="4" class="py-0 pl-0 text-right">{{
            transportation_money.toLocaleString()
          }}</v-col>
        </v-row>
        <v-row v-if="extension_name" class="my-1">
          <v-col cols="8" class="pa-0"
            >{{ $t('extension') }}:{{ extension_name }}</v-col
          ><v-col cols="4" class="py-0 pl-0 text-right">{{
            extension_money
          }}</v-col>
        </v-row>
        <v-row v-if="discount_money !== Number(0)" class="my-1">
          <v-col cols="8" class="pa-0">{{ $t('discount') }}</v-col
          ><v-col cols="4" class="py-0 pl-0 text-right"
            >-{{ discount_money.toLocaleString() }}</v-col
          >
        </v-row>
        <!-- <v-row v-if="point !== Number(0)" class="my-1">
          <v-col cols="8" class="pa-0">ポイント</v-col
          ><v-col cols="4" class="py-0 pl-0 text-right"
            >-{{ point.toLocaleString() }}</v-col
          >
        </v-row> -->
        <v-row class="my-1">
          <v-divider></v-divider>
        </v-row>
        <v-row class="my-1">
          <v-col cols="8" class="pa-0">{{ $t('total') }}</v-col
          ><v-col cols="4" class="py-0 pl-0 text-right">{{
            total_money.toLocaleString()
          }}</v-col>
        </v-row>
        <!-- <v-row v-if="give_back_point > 0" class="my-1">
          <v-col cols="12" class="text-center" style="background-color:#fff;">
            <div>還元予定ポイント</div>
            <div align="center">
              <IconPoint
                class=""
                style="fill:#E4C73F; vertical-align: bottom;"
              />
              <span
                style="color:#B22C28;font-size:var(--read-font-size-l);font-weight:bold;"
              >
                {{ Math.floor(give_back_point).toLocaleString() }} P
                <v-btn
                  x-small
                  rounded
                  color="#DEDEDE"
                  style="font-size:var(--read-font-size-s);"
                  @click="giveBackPointDetailDialog = true"
                  >詳細</v-btn
                >
              </span>
            </div>
          </v-col>
        </v-row> -->
      </v-col>
    </v-row>

    <!-- <v-dialog
      v-if="give_back_point > 0"
      v-model="giveBackPointDetailDialog"
      max-width="350"
      transition="dialog-bottom-transition"
      style="z-index:9999"
    >
      <v-card class="py-2 px-4" style="font-size:0.875rem;position:relative;">
        <div class="text-center primary--text" style="font-weight:bold;">
          <IconPoint style="fill:#E4C73F; vertical-align: bottom;" />
          還元予定ポイント
        </div>
        <div style="color:var(--main-title-color);">
          <v-row>
            <v-col class="py-0" cols="8">
              利用額還元
            </v-col>
            <v-col class="py-0" cols="4">
              {{
                Math.floor(
                  total_money * giveBackPointData.reduction_rate
                ).toLocaleString()
              }}
              P
            </v-col>
          </v-row>
          <v-row
            v-for="(pointData, index) in giveBackPointData.detail"
            :key="`pointData-${index}`"
            v-if="pointData.displayFlag"
          >
            <v-col class="py-0" cols="8">
              {{ pointData.displayName }}
            </v-col>
            <v-col class="py-0" cols="4">
              {{ pointData.point.toLocaleString() }} P
            </v-col>
          </v-row>
        </div>

        <div class="text-right pt-2">
          <v-btn
            small
            @click="giveBackPointDetailDialog = false"
            style="text-decoration:none;"
            >閉じる</v-btn
          >
        </div>
      </v-card>
    </v-dialog> -->
  </v-container>
</template>

<script>
import noimage_m from '~/assets/img/noimage_m.png';
import ngimage_m from '~/assets/img/ngimage_m.png';
import IconPoint from '~/assets/img/icon-point.svg';

export default {
  components: {
    IconPoint,
  },
  data: () => ({
    noimage_m: noimage_m,
    ngimage_m: ngimage_m,
    resizeFlag: false,
    giveBackPointDetailDialog: false,
    imageHeight: 190,
  }),
  props: [
    'cast',
    'cast_free',
    'cast_id',
    'course_money',
    'designation_money',
    'before_discount_designation_money',
    'cast_additional_money',
    'option_money',
    'transportation_money',
    'extension_name', // 延長名(スペシャルカード用)
    'extension_money', // 延長料金(スペシャルカード用)
    'tip_money',
    'discount_money',
    'point',
    'give_back_point',
    'total_money',
    'isPrivateFlag',
    'giveBackPointData',
  ],
  computed: {
    // 指名料金 + キャスト追加料金
    designationMoneyAddCastAdditionalMoney: function() {
      if (this.before_discount_designation_money) {
        return (
          Number(this.before_discount_designation_money) +
          Number(this.cast_additional_money)
        );
      } else {
        return (
          Number(this.designation_money) + Number(this.cast_additional_money)
        );
      }
    },
  },
  created: function() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  methods: {
    handleResize: function() {
      if (window.innerWidth <= 320) {
        this.imageHeight = 120;
      } else {
        this.imageHeight = 190;
      }
    },
    defaultImage(thumbnailUrl, defaultImageName) {
      if (this.cast_free) {
        return this.noimage_m;
      }
      if (!thumbnailUrl) {
        if (defaultImageName.search('ng') == -1) {
          return this.ngimage_m;
        } else {
          return this.noimage_m;
        }
      } else {
        return this.isPrivateFlag ? this.noimage_m : thumbnailUrl;
      }
    },
  },
};
</script>

<style scoped>
.usr-call-reserve__total-header {
  font-weight: bold;
}
.usr-call-reserve__detail-header {
  font-size: var(--read-font-size-s);
  font-weight: bold;
  line-height: var(--read-font-size-l);
  color: var(--read-font-color-primary);
}

.cast-list__text {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 1;
  font-size: var(--read-font-size-s);
  color: #fff;
  line-height: 0.8;
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.02) 3%,
    rgba(0, 0, 0, 0.36) 56%,
    rgba(0, 0, 0, 0.65) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.02) 3%,
    rgba(0, 0, 0, 0.36) 56%,
    rgba(0, 0, 0, 0.65) 100%
  );
}
</style>
