<template>
  <v-dialog v-model="dialogFlag" max-width="350" style="z-index:9999">
    <v-card
      class="py-2 px-4"
      color="primary"
      style="color:#fff;font-size:var(--read-font-size-s);"
    >
      <v-row>
        <v-col class="pa-0" cols="2">
          <v-layout column fill-height>
            <v-container class="mt-4 pa-0 text-center">
              <v-icon color="white">mdi-alert</v-icon>
            </v-container>
          </v-layout>
        </v-col>
        <v-col class="px-1" cols="10">
          <div class="pb-2">{{ $t('text_f6vm') }}</div>
        </v-col>
      </v-row>
      <div class="text-right">
        <v-btn
          small
          @click="dialogFlag = false"
          style="text-decoration:none;"
          >{{ $t('back') }}</v-btn
        >
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialogFlag: false,
  }),
};
</script>
