import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"5"}},[_c(VImg,{attrs:{"src":_vm.defaultImage(
            _vm.cast.thumbnailUrl,
            _vm.cast.shop_default_thumbnail_set.set_name
          ),"max-height":_vm.imageHeight,"contain":""},on:{"error":function($event){_vm.cast.thumbnailUrl = _vm.noimage_m}}},[_c(VRow,{staticClass:"cast-list__text ma-0",attrs:{"align":"end"}},[_c(VCol,[_c('p',{staticClass:"ma-0"},[_vm._v("\n              "+_vm._s(this.isPrivateFlag || this.cast_free
                  ? _vm.$t('free')
                  : _vm.cast.site_cast_name)+"\n            ")])])],1)],1)],1),_vm._v(" "),_c(VCol,{staticClass:"usr-call-reserve__detail-header",attrs:{"cols":"7"}},[_c(VRow,{staticClass:"my-1"},[_c(VCol,{staticClass:"pa-0",attrs:{"cols":"8"}},[_vm._v(_vm._s(_vm.$t('course')))]),_c(VCol,{staticClass:"py-0 pl-0 text-right",attrs:{"cols":"4"}},[_vm._v(_vm._s(_vm.course_money.toLocaleString()))])],1),_vm._v(" "),(_vm.before_discount_designation_money)?_c(VRow,{staticClass:"my-1"},[_c(VCol,{staticClass:"pa-0",attrs:{"cols":"8"}},[_vm._v(_vm._s(_vm.$t('designated_fee')))]),_c(VCol,{staticClass:"py-0 pl-0 text-right",attrs:{"cols":"4"}},[_vm._v(_vm._s(_vm.designationMoneyAddCastAdditionalMoney.toLocaleString()))]),_vm._v(" "),_c(VCol,{staticClass:"pa-0",attrs:{"cols":"8"}},[_vm._v(_vm._s(_vm.$t('special_card')))]),_c(VCol,{staticClass:"py-0 pl-0 text-right",attrs:{"cols":"4"}},[_vm._v("-"+_vm._s(_vm.before_discount_designation_money.toLocaleString()))])],1):(
          !_vm.before_discount_designation_money &&
            _vm.designationMoneyAddCastAdditionalMoney > Number(0)
        )?_c(VRow,{staticClass:"my-1"},[_c(VCol,{staticClass:"pa-0",attrs:{"cols":"8"}},[_vm._v(_vm._s(_vm.$t('designated_fee')))]),_c(VCol,{staticClass:"py-0 pl-0 text-right",attrs:{"cols":"4"}},[_vm._v(_vm._s(_vm.designationMoneyAddCastAdditionalMoney.toLocaleString()))])],1):_vm._e(),_vm._v(" "),(_vm.option_money > Number(0))?_c(VRow,{staticClass:"my-1"},[_c(VCol,{staticClass:"pa-0",attrs:{"cols":"8"}},[_vm._v(_vm._s(_vm.$t('option')))]),_c(VCol,{staticClass:"py-0 pl-0 text-right",attrs:{"cols":"4"}},[_vm._v(_vm._s(_vm.option_money.toLocaleString()))])],1):_vm._e(),_vm._v(" "),_c(VRow,{staticClass:"my-1"},[_c(VCol,{staticClass:"pa-0",attrs:{"cols":"8"}},[_vm._v(_vm._s(_vm.$t('transportation_expenses')))]),_c(VCol,{staticClass:"py-0 pl-0 text-right",attrs:{"cols":"4"}},[_vm._v(_vm._s(_vm.transportation_money.toLocaleString()))])],1),_vm._v(" "),(_vm.extension_name)?_c(VRow,{staticClass:"my-1"},[_c(VCol,{staticClass:"pa-0",attrs:{"cols":"8"}},[_vm._v(_vm._s(_vm.$t('extension'))+":"+_vm._s(_vm.extension_name))]),_c(VCol,{staticClass:"py-0 pl-0 text-right",attrs:{"cols":"4"}},[_vm._v(_vm._s(_vm.extension_money))])],1):_vm._e(),_vm._v(" "),(_vm.discount_money !== Number(0))?_c(VRow,{staticClass:"my-1"},[_c(VCol,{staticClass:"pa-0",attrs:{"cols":"8"}},[_vm._v(_vm._s(_vm.$t('discount')))]),_c(VCol,{staticClass:"py-0 pl-0 text-right",attrs:{"cols":"4"}},[_vm._v("-"+_vm._s(_vm.discount_money.toLocaleString()))])],1):_vm._e(),_vm._v(" "),_c(VRow,{staticClass:"my-1"},[_c(VDivider)],1),_vm._v(" "),_c(VRow,{staticClass:"my-1"},[_c(VCol,{staticClass:"pa-0",attrs:{"cols":"8"}},[_vm._v(_vm._s(_vm.$t('total')))]),_c(VCol,{staticClass:"py-0 pl-0 text-right",attrs:{"cols":"4"}},[_vm._v(_vm._s(_vm.total_money.toLocaleString()))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }