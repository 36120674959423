export default {
    methods: {
        isPrivateFlag: function(reservation) {
            if (!reservation || !reservation.reservation_histories) return false;
            let isPrivateFlag = false;
            // 予約履歴に1つでもcast_free_flagが1のレコードがある、かつ、予約ステータスが入室未満かキャンセルのステータスの場合公開しない
            const status = parseInt(reservation.status)
            reservation.reservation_histories.forEach((reservation_history) => {
              if (parseInt(reservation_history.cast_free_flag) === 1 && (status < 3 || status === 7)) isPrivateFlag = true;
            });
            return isPrivateFlag;
        }
    }
}