<template>
  <div class="cast-like-button" @click.stop="follow()">
    <v-icon
      v-if="this.$store.state.auth.loggedIn === true && favorite === true"
      size="20"
      color="#B22C28"
      class="cast-like-button__icon"
      >mdi-heart</v-icon
    >
    <v-icon v-else size="20" color="#B22C28" class="cast-like-button__icon"
      >mdi-heart-outline</v-icon
    >
    <div class="cast-like-button__liked-count">{{ count }}</div>
    <GuestAlertDialog :message="guestAlertMessage" ref="guestAlert" />
  </div>
</template>

<script>
import GuestAlertDialog from '~/components/pages/dialog/GuestAlertDialog.vue';
export default {
  components: {
    GuestAlertDialog,
  },
  data() {
    return {
      favorite: false,
      guestAlertMessage: '',
      following: false,
    };
  },
  created() {
    this.favorite = this.isFavorite;
    this.following = this.$store.state.siteMemberData.followCastIds;
    this.count = this.siteMemberFollowCount;
  },
  props: {
    castId: {
      type: Number,
      require: true,
    },
    isFavorite: {
      type: [Boolean, Number],
      required: true,
    },
    siteMemberFollowCount: {
      type: Number,
      required: true,
    },
  },
  watch: {
    isFavorite(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.favorite = newValue;
      }
    },
    siteMemberFollowCount(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.count = this.siteMemberFollowCount;
      }
    },
  },
  methods: {
    follow() {
      if (this.$store.state.auth.loggedIn === false) {
        this.$refs.guestAlert.dialog = true;
        this.isGuestDialog(this.$t('login_text_10'));
        return;
      }
      this.favorite = !this.favorite;
      this.count += this.favorite ? 1 : -1;
      /**
       * 会員store siteMemberData.followCastIds をアップデートする
       */
      const followCastIds = this.updatefollowCastIds(
        this.$store.state.siteMemberData.followCastIds,
        this.castId,
        this.favorite
      );
      this.$store.dispatch('siteMemberData/commitFollowCastIds', followCastIds);
      /**
       * フォロワー数store followerCountData/●● をアップデートする
       */
      const followerCount = this.updateFollowerCount(
        this.$store.state.followerCountData,
        this.castId,
        this.favorite
      );
      this.$store.dispatch(
        'followerCountData/updateFollowerCountData',
        followerCount
      );
      /** emit */
      this.$emit('emitFav', this.favorite);
      /** axios */
      const params = {
        email: this.$store.state.auth.user.email,
        castId: this.castId,
        fav: this.favorite,
      };
      this.$axios
        .post('private/save_follow_cast', params, {
          headers: {
            Authorization: `${this.$auth.$storage.getUniversal(
              '_token.auth0',
              true
            )}`,
          },
        })
        .then((response) => {
          this.$store.dispatch('ssr/firstLoadTime', '');
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.cast-like-button {
  height: 38px;
  width: 38px;
  background: #fff;
  position: relative;
  border-radius: 50%;
  text-align: center;
  box-shadow: 0 0 6px 0 #00000029;
  .cast-like-button__icon {
    position: absolute;
    transform: translateX(-50%);
    top: 4px;
    left: 50%;
  }
  .cast-like-button__liked-count {
    position: absolute;
    transform: translateX(-50%);
    top: 20px;
    left: 50%;
    width: 25px;
    font-size: 10px;
    font-weight: bold;
    color: #B22C27;
    line-height: 1rem;
  }
}
</style>