import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{staticStyle:{"z-index":"9999"},attrs:{"max-width":"350"},model:{value:(_vm.dialogFlag),callback:function ($$v) {_vm.dialogFlag=$$v},expression:"dialogFlag"}},[_c(VCard,{staticClass:"py-2 px-4",staticStyle:{"color":"#fff","font-size":"var(--read-font-size-s)"},attrs:{"color":"primary"}},[_c(VRow,[_c(VCol,{staticClass:"pa-0",attrs:{"cols":"2"}},[_c(VLayout,{attrs:{"column":"","fill-height":""}},[_c(VContainer,{staticClass:"mt-4 pa-0 text-center"},[_c(VIcon,{attrs:{"color":"white"}},[_vm._v("mdi-alert")])],1)],1)],1),_vm._v(" "),_c(VCol,{staticClass:"px-1",attrs:{"cols":"10"}},[_c('div',{staticClass:"pb-2"},[_vm._v(_vm._s(_vm.$t('text_f6vm')))])])],1),_vm._v(" "),_c('div',{staticClass:"text-right"},[_c(VBtn,{staticStyle:{"text-decoration":"none"},attrs:{"small":""},on:{"click":function($event){_vm.dialogFlag = false}}},[_vm._v(_vm._s(_vm.$t('back')))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }