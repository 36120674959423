<template>
  <v-main
    style="background-color: #fff"
    v-if="Object.keys(this.castData).length === 0"
  >
    <loading-image />
  </v-main>
  <v-main style="background-color: #fff" v-else-if="this.nicknameErrorMessage">
    <v-container class="text-center mb-12">
      <div>
        <div class="pa-3 primary--text text-center">
          {{ $t('text_n19e') }}
        </div>
        <div class="pa-3 text-left">{{ $t('text_au0b') }}<br /><br /></div>
        <div class="px-3">
          <v-btn
            block
            elevation="0"
            :to="`/${$i18n.locale}/account/profile-edit`"
            color="primary"
            style="font-size: var(--read-font-size-m); font-weight: bold"
            >{{ $t('text_4lbg') }}</v-btn
          >
        </div>
      </div>
      <v-btn text :to="`/${$i18n.locale}`" color="primary">{{
        $t('back_to_top')
      }}</v-btn>
    </v-container>
  </v-main>
  <v-main
    style="background-color: #fff"
    v-else-if="this.castData.reservationNg"
  >
    <v-container class="text-center mb-12">
      <div>
        {{ $t('text_af9s') }}<br />
        {{ $t('text_26nc') }}<br />
        <div v-if="castData.shop.shop_tel" class="pb-2">
          {{ castData.shop.name }}:
          <a
            :href="'tel:' + castData.shop.shop_tel"
            style="font-size: 0.9rem"
            >{{ castData.shop.shop_tel }}</a
          >
        </div>
      </div>
      <v-btn text :to="`/${$i18n.locale}`" color="primary">{{
        $t('back_to_top')
      }}</v-btn>
    </v-container>
  </v-main>

  <v-main style="background-color: #fff" v-else>
    <v-container style="align-items: center; display: inline-flex">
      <v-icon size="2rem" color="primary">mdi-cellphone-iphone</v-icon>
      <h1 class="primary--text">{{ $t('text_18a9') }}</h1>
    </v-container>

    <v-divider></v-divider>

    <v-container>
      <div class="my-2">
        <v-icon
          size="3.5vh"
          style="color: var(--read-font-color-sub); padding-bottom: 2px"
          >mdi-clock</v-icon
        >
        <div class="usr-call-step2-cond__header">{{ $t('text_0q9f') }}</div>
      </div>
      <v-row class="px-4">
        <v-col cols="8" class="pl-8 pr-0 py-0 usr-call-step2-cond_reserve_date">
          {{ reserveDateTime | reserve_moment }}～
        </v-col>
        <v-col cols="4" v-if="castData.url_hash && !castFree" class="pl-0 py-0">
          <v-btn
            v-if="this.$store.state.castDialog.selectedCastReserveDialog"
            v-on:click="closeCastDialog()"
            class="usr-call-step2-cond_change-day"
            height="auto"
            width="100%"
            elevation="0"
            color="#fff"
          >
            <v-icon size="3.5vh" style="color: var(--read-font-color-sub)"
              >mdi-calendar-clock</v-icon
            >{{ $t('change') }}</v-btn
          >
          <v-btn
            v-else
            class="usr-call-step2-cond_change-day"
            height="auto"
            width="100%"
            elevation="0"
            color="#fff"
            @click="
              toCastScheduleForChangeReserve(castData.url_hash, reserveId)
            "
          >
            <v-icon size="3.5vh" style="color: var(--read-font-color-sub)"
              >mdi-calendar-clock</v-icon
            >{{ $t('change') }}</v-btn
          >
        </v-col>
      </v-row>
      <div v-if="reserveDateTimeMessage" class="pl-8 pb-1 pt-6">
        <span
          style="
            color: red;
            font-size: var(--read-font-size-s);
            font-weight: bold;
          "
          >{{ $t('text_p2w0') }}</span
        >
      </div>
    </v-container>
    <v-divider></v-divider>

    <v-container>
      <div class="my-2">
        <v-icon
          size="3.5vh"
          style="color: var(--read-font-color-sub); padding-bottom: 2px"
          >mdi-timer-sand</v-icon
        >
        <div class="usr-call-step2-cond__header">{{ $t('text_ma5m') }}</div>
        <span
          style="
            color: var(--main-color);
            font-size: var(--read-font-size-s);
            font-weight: bold;
          "
          >{{ $t('required') }}</span
        >
        <p
          v-if="upCategory === 4"
          style="
            font-size: var(--read-font-size-s);
            color: var(--read-font-color-sub);
            font-weight: bold;
          "
        >
          {{ $t('text_q9my') }}
        </p>
        <p
          v-if="castData.shop.site_member_site_reservation_play_time_comment"
          style="
            font-size: var(--read-font-size-s);
            color: var(--read-font-color-sub);
            font-weight: bold;
          "
        >
          ※{{ castData.shop.site_member_site_reservation_play_time_comment }}
        </p>
      </div>

      <!-- <div
        class="point-setting pa-3 mb-5"
        v-if="
          this.giveBackPointData.length != 0 &&
            Object.keys(this.giveBackPointData.course).length != 0
        "
        :class="{ 'pb-1': !this.giveBackPointData.course[0].endDate }"
      >
        <div class="d-flex align-center mb-4">
          <div class="point-set-code mr-5 py-1">コース</div>
          <div class="point-set-description">
            特定のコースでご利用のお客様に
          </div>
        </div>
        <div class="d-flex align-center mb-4">
          <div class="mr-4 point-set-code-icon-box">
            <IconTime class="point-set-code-icon" />
          </div>
          <IconPoint class="mr-3 icon-point" style="fill: #E4C73F;" />
          <div class="point-present-2">
            ポイントプレゼント！<IconRadiation class="icon-radiation" />
          </div>
        </div>
        <div class="point-concurrent-use pa-1">
          {{
            concurrentUseText(
              this.giveBackPointData.course[0].concurrentUseFlag
            )
          }}
        </div>
        <div class="target-description">
          <div
            :key="course.id"
            v-for="course in giveBackPointData.course"
            class="d-flex align-center py-1"
            style="border-bottom: 1px solid #FFF;"
            v-if="course.courseName"
          >
            <div class="mr-8" style="width: 60%;">
              【対象】{{ course.courseName }}
            </div>
            <div class="d-flex align-center" style="width: 40%;">
              <IconPoint class="mr-2 icon-point-small" style="fill: #E4C73F;" />
              <div>{{ pointDisplay(course.point) }}P</div>
            </div>
          </div>
        </div>
        <div
          class="point-expiry-date pa-1"
          v-if="endDateDisplayFlag(this.giveBackPointData.course[0].endDate)"
        >
          このキャンペーンは{{
            this.$moment(this.giveBackPointData.course[0].endDate).format(
              'YYYY年MM月DD日'
            )
          }}までにご利用いただいた場合のみ
          還元されます。ご予約日ではございませんのでご注意ください
        </div>
      </div> -->

      <v-btn-toggle
        v-model="courseValue"
        mandatory
        style="width: 100%; display: inline-block"
      >
        <div
          v-for="item in this.serviceData['course']"
          :key="item.id"
          style="width: 50%; display: inline-block; font-size: 0.8rem"
          align="center"
          class="px-2 py-1"
          id="usr-call-step2-cond_course__wrapper"
          v-on:click="
            ngAlert(
              checkCastNgCourse(item.name) || checkTalentNgItem(1, item.name),
              $t('course')
            )
          "
        >
          <v-btn
            height="auto"
            small
            class="usr-call-step2-cond_course__btn disabled"
            elevation="0"
            v-if="checkCastNgCourse(item.name)"
            :value="item.id"
            disabled
          >
            <ul class="pa-0" style="list-style: none; width: 100%">
              <li
                class="text-capitalize"
                style="width: 100%; overflow-wrap: break-word"
              >
                {{ item.minute }}{{ $t('min') }}
              </li>
              <li
                class="pa-0"
                style="
                  font-size: var(--read-font-size-s);
                  color: var(--read-font-color-gold);
                "
              >
                {{ item.service_money.toLocaleString() }}{{ $t('yen') }}
              </li>
            </ul>
            <v-icon size="1rem">mdi-alert</v-icon>
          </v-btn>
          <v-btn
            height="auto"
            small
            class="usr-call-step2-cond_course__btn disabled"
            elevation="0"
            v-else-if="checkTalentNgItem(1, item.name)"
            :value="item.id"
            disabled
          >
            <ul class="pa-0" style="list-style: none; width: 100%">
              <li
                class="text-capitalize"
                style="width: 100%; overflow-wrap: break-word"
              >
                {{ item.minute }}{{ $t('min') }}
              </li>
              <li
                class="pa-0"
                style="
                  font-size: var(--read-font-size-s);
                  color: var(--read-font-color-gold);
                "
              >
                {{ item.service_money.toLocaleString() }}{{ $t('yen') }}
              </li>
            </ul>
            <v-icon size="1rem">mdi-alert</v-icon>
          </v-btn>
          <v-btn
            height="auto"
            small
            class="usr-call-step2-cond_course__btn disabled"
            elevation="0"
            v-else-if="item.minute < minCourseMinute"
            :value="item.id"
            disabled
          >
            <ul class="pa-0" style="list-style: none; width: 100%">
              <li
                class="text-capitalize"
                style="width: 100%; overflow-wrap: break-word"
              >
                {{ item.minute }}{{ $t('min') }}
              </li>
              <li
                class="pa-0"
                style="
                  font-size: var(--read-font-size-s);
                  color: var(--read-font-color-gold);
                "
              >
                {{ item.service_money.toLocaleString() }}{{ $t('yen') }}
              </li>
            </ul>
          </v-btn>
          <v-btn
            height="auto"
            small
            outlined
            class="usr-call-step2-cond_course__btn"
            elevation="0"
            v-else-if="item.possibleFlag === true"
            :value="item.id"
          >
            <ul class="pa-0" style="list-style: none; width: 100%">
              <li
                class="text-capitalize"
                style="width: 100%; overflow-wrap: break-word"
              >
                {{ item.minute }}{{ $t('min') }}
              </li>
              <li
                class="pa-0"
                style="
                  font-size: var(--read-font-size-s);
                  color: var(--read-font-color-gold);
                "
              >
                {{ item.service_money.toLocaleString() }}{{ $t('yen') }}
              </li>
            </ul>
          </v-btn>
          <v-btn
            height="auto"
            small
            class="usr-call-step2-cond_course__btn disabled"
            elevation="0"
            v-else
            :value="item.id"
            disabled
          >
            <ul class="pa-0" style="list-style: none; width: 100%">
              <li style="width: 100%; overflow-wrap: break-word; text-transform: lowercase;">
                {{ item.offical_site_display_name }}
              </li>
              <li
                class="pa-0"
                style="
                  font-size: var(--read-font-size-s);
                  color: var(--read-font-color-gold);
                "
              >
                {{ item.service_money.toLocaleString() }}{{ $t('yen') }}
              </li>
            </ul>
          </v-btn>
        </div>
      </v-btn-toggle>
      <div v-if="Object.keys(this.serviceData).length === 0" class="pr-4">
        <loading-image />
      </div>
    </v-container>

    <v-divider></v-divider>
    <v-container
      v-if="this.serviceData['option'] && this.serviceData['option'] != ''"
    >
      <div class="my-2">
        <v-icon
          size="3.5vh"
          style="color: var(--read-font-color-sub); padding-bottom: 2px"
          >mdi-tag-multiple</v-icon
        >
        <div class="usr-call-step2-cond__header">
          {{ $t('text_hf9q') }}
        </div>
        <p
          v-if="castData.shop.site_member_site_reservation_option_comment"
          style="
            font-size: var(--read-font-size-s);
            color: var(--read-font-color-sub);
            font-weight: bold;
          "
        >
          ※{{ castData.shop.site_member_site_reservation_option_comment }}
        </p>
      </div>

      <!-- <div
        class="point-setting pa-3 mb-5"
        v-if="
          this.giveBackPointData.length != 0 &&
            Object.keys(this.giveBackPointData.option).length != 0
        "
        :class="{ 'pb-1': !this.giveBackPointData.option[0].endDate }"
      >
        <div class="d-flex align-center mb-4">
          <div class="point-set-code mr-5 pa-1">OP</div>
          <div class="point-set-description">
            特定のオプションをご利用のお客様に
          </div>
        </div>
        <div class="d-flex align-center mb-4">
          <div class="mr-4 point-set-code-icon-box">
            <IconOption class="point-set-code-icon" />
          </div>
          <IconPoint class="mr-3 icon-point" style="fill: #E4C73F;" />
          <div class="point-present-2">
            ポイントプレゼント！<IconRadiation class="icon-radiation" />
          </div>
        </div>
        <div class="point-concurrent-use pa-1">
          {{
            concurrentUseText(
              this.giveBackPointData.option[0].concurrentUseFlag
            )
          }}
        </div>
        <div class="target-description pa-1">
          <div>【対象】{{ giveBackPointOptionName() }}</div>
        </div>
        <div
          class="point-expiry-date pa-1"
          v-if="endDateDisplayFlag(this.giveBackPointData.option[0].endDate)"
        >
          このキャンペーンは{{
            this.$moment(this.giveBackPointData.option[0].endDate).format(
              'YYYY年MM月DD日'
            )
          }}までにご利用いただいた場合のみ
          還元されます。ご予約日ではございませんのでご注意ください
        </div>
      </div> -->

      <v-row class="px-3">
        <v-col
          v-for="item in this.serviceData['option']"
          v-on:click="
            ngAlert(
              checkCastNgOption(item.name) || checkTalentNgItem(0, item.name),
              $t('option')
            )
          "
          :key="item.id"
          cols="12"
          class="my-0 pa-0"
          style="height: 45px; font-weight: bold"
        >
          <v-checkbox
            v-model="optionValue"
            :value="item.id"
            v-bind:disabled="
              checkCastNgOption(item.name) || checkTalentNgItem(0, item.name)
            "
            class="ma-0"
          >
            <template v-slot:label>
              {{
                `${
                  item.offical_site_display_name
                } (¥${item.service_money.toLocaleString()})`
              }}
              <!-- <span
                v-if="
                  giveBackPointData.length != 0 &&
                    Object.keys(giveBackPointData.option).length != 0 &&
                    giveBackPointOption(item.id)
                "
              >
                <span class="point-set-code-icon-box">
                  <IconPoint
                    class="ml-2 point-set-code-icon"
                    style="fill:#E4C73F; vertical-align:bottom; width:16px;"
                  />
                  <span
                    style="color:var(--main-color); font-size: var(--read-font-size-s);"
                    >{{ giveBackPointOption(item.id) }}P</span
                  >
                </span>
              </span> -->
              <v-icon
                v-if="
                  checkCastNgOption(item.name) ||
                    checkTalentNgItem(0, item.name)
                "
                size="1rem"
                >mdi-alert</v-icon
              >
            </template>
          </v-checkbox>
        </v-col>
      </v-row>
    </v-container>
    <v-divider></v-divider>

    <div
      v-if="
        siteReservationServiceData.length !== 0 &&
          Object.keys(this.siteReservationServiceData['serviceSet']).length !==
            0
      "
    >
      <div
        v-for="serviceSet in this.siteReservationServiceData['serviceSet']"
        :key="serviceSet.id"
      >
        <v-container
          v-if="
            serviceSet.type === 3 ||
              (siteReservationServiceData['service'] &&
                siteReservationServiceData['service'][serviceSet.id])
          "
        >
          <div class="my-2">
            <v-icon
              size="3.5vh"
              style="color: var(--read-font-color-sub); padding-bottom: 2px"
              >mdi-tag-plus</v-icon
            >
            <div class="usr-call-step2-cond__header">{{ serviceSet.name }}</div>
            <p
              v-if="serviceSet.remarks"
              style="
                font-size: var(--read-font-size-s);
                color: var(--read-font-color-sub);
                font-weight: bold;
              "
            >
              {{ serviceSet.remarks }}
            </p>
          </div>
          <div v-if="serviceSet.type === 1">
            <v-col
              v-for="(service, index) in siteReservationServiceData['service'][
                serviceSet.id
              ]"
              :key="index"
              cols="12"
              class="my-0 pa-0"
              style="font-weight: bold"
            >
              <v-checkbox
                v-model="
                  siteReservationServiceDataLabel['radio'][serviceSet.id]
                "
                :label="service.name"
                :value="service.id"
                on-icon="mdi-radiobox-marked"
                off-icon="mdi-radiobox-blank"
                class="ma-0"
              />
            </v-col>
          </div>
          <v-row v-if="serviceSet.type === 2" class="px-3">
            <v-col
              v-for="(service, index) in siteReservationServiceData['service'][
                serviceSet.id
              ]"
              :key="index"
              cols="12"
              class="my-0 pa-0"
              style="font-weight: bold"
            >
              <v-checkbox
                v-model="
                  siteReservationServiceDataLabel['checkbox'][serviceSet.id]
                "
                :label="service.name"
                :value="service.id"
                class="ma-0"
              />
            </v-col>
          </v-row>
          <v-row v-if="serviceSet.type === 3" class="px-3">
            <v-col cols="12" class="my-0 pa-0" style="font-weight: bold">
              <v-text-field
                v-model="siteReservationServiceDataLabel['text'][serviceSet.id]"
                @focus="switchIsFocused(true)"
                @blur="switchIsFocused(false)"
                :label="$t('text_x9gw')"
                class="ma-0"
              />
            </v-col>
          </v-row>
        </v-container>
        <v-divider></v-divider>
      </div>
    </div>

    <v-container>
      <div class="my-2">
        <v-icon
          size="3.5vh"
          style="color: var(--read-font-color-sub); padding-bottom: 2px"
          >mdi-credit-card-outline</v-icon
        >
        <div class="usr-call-step2-cond__header">
          {{ $t('text_k3u4') }}
        </div>
        <span
          style="
            color: var(--main-color);
            font-size: var(--read-font-size-s);
            font-weight: bold;
          "
          >{{ $t('required') }}</span
        >
      </div>

      <v-radio-group v-model="paymentMethodValue">
        <img :src="cardImg" class="call-cardImg" />
        <v-radio
          v-for="n in Object.entries(paymentMethodItems).length"
          :key="n"
          :label="paymentMethodItems[n].label"
          :value="n"
          style="font-weight: bold"
        ></v-radio>
      </v-radio-group>
      <div
        style="
          font-size: var(--read-font-size-xs);
          font-weight: bold;
          color: var(--main-color);
        "
      >
        {{ $t('text_u0j2') }}
      </div>
    </v-container>
    <v-divider></v-divider>

    <!-- <div>
      <v-container>
        <div class="my-2">
          <v-icon
            size="3.5vh"
            style="color:var(--read-font-color-sub);padding-bottom:2px;"
            >mdi-alpha-p-box</v-icon
          >
          <div class="usr-call-step2-cond__header">
            使用ポイントをご入力ください
          </div>
        </div>
        <div class="mt-2">
          <div class="pl-8" style="font-size:var(--read-font-size-s);">
            利用可能ポイント {{ availablePoint }}P
          </div>
        </div>

        <v-row class="mx-0">
          <v-col cols="5" class="my-0 pa-0">
            <v-radio-group
              style="margin-top: 0px !important;"
              v-model="pointDataValue"
            >
              <v-radio
                v-for="(item, index) in pointData"
                :key="index"
                :label="item.label"
                :value="item.value"
                style="font-weight:bold; margin-right: 0px !important;"
                :class="readonlyPointRadio ? 'point-readonly' : ''"
                :color="readonlyPointRadio ? 'rgba(0, 0, 0, 0.38)' : ''"
                :readonly="readonlyPointRadio"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="7" class="my-0 pa-0">
            <input
              v-model="specifiedPoint"
              type="number"
              class="call-point-input"
              @input="pointValidate"
              :disabled="disabledPointInput"
            />
          </v-col>
        </v-row>
        <div
          v-if="pointDataValue == 0 || pointDataValue == 1"
          style="font-size:var(--read-font-size-xs);font-weight:bold;color:var(--main-color);"
        >
          ※ポイント使用時は割引、クーポン券等の併用はできません。
        </div>
        <div
          style="font-size:var(--read-font-size-xs);font-weight:bold;color:var(--main-color);"
        >
          {{ this.pointNote }}
        </div>
        <div
          v-if="paymentMethodValue == 1 && Number(totalMoney) == 0"
          style="font-size:var(--read-font-size-xs);font-weight:bold;color:var(--main-color);"
        >
          ※カード決済の場合、総額が0円のご予約は承れません。現金決済を選択してください。
        </div>
      </v-container>
      <v-divider></v-divider>
    </div> -->

    <v-container>
      <div class="my-2">
        <v-icon
          size="3.5vh"
          style="color: var(--read-font-color-sub); padding-bottom: 2px"
          >mdi-map-marker</v-icon
        >
        <div class="usr-call-step2-cond__header">{{ $t('text_7i76') }}</div>
        <p
          v-if="castData.shop.site_member_site_reservation_place_use_comment"
          style="
            font-size: var(--read-font-size-s);
            color: var(--read-font-color-sub);
            font-weight: bold;
          "
        >
          ※{{ castData.shop.site_member_site_reservation_place_use_comment }}
        </p>
      </div>
      <div>
        <v-card elevation="0" class="px-4">
          <v-tabs-items
            v-model="this.$store.state.siteMemberData.selectPlace"
            class="mt-4"
            touchless
          >
            <v-tab-item value="selected-home">
              <v-card flat>
                <div>
                  <p class="pl-10">
                    {{ this.$store.state.siteMemberData.home_city_text }}<br />
                    {{ this.$store.state.siteMemberData.home_address }}
                    {{ this.$store.state.siteMemberData.home_building }}
                    {{ this.$store.state.siteMemberData.home_room_number }}
                  </p>
                </div>
              </v-card>
            </v-tab-item>
            <v-tab-item value="selected-hotel">
              <v-row>
                <v-col cols="12">
                  <v-card flat v-if="this.hotels.length > 0">
                    <div>
                      <p
                        style="
                          font-size: var(--read-font-size-s);
                          color: var(--read-font-color-sub);
                          font-weight: bold;
                        "
                      >
                        {{ $t('text_zf43') }}<br />
                        <br />
                        <span style="color: red; font-weight: bold">{{
                          $t('text_s6m7')
                        }}</span>
                      </p>
                      <v-row dense v-if="ngHotels.length > 0">
                        <v-col cols="12">
                          <v-card outlined>
                            <v-card-title
                              class="subtitle-2"
                              style="color: var(--main-color)"
                            >
                              {{ $t('text_5wa8') }}<br />
                              {{ $t('text_g6hc') }}
                            </v-card-title>
                            <v-card-text>
                              <v-chip
                                v-for="n in ngHotels"
                                :key="n.id"
                                color="primary"
                                outlined
                                class="mx-1"
                                style="
                                  white-space: normal !important;
                                  height: 100% !important;
                                "
                              >
                                {{ n['name_' + selectLocale] }}
                              </v-chip>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                      <FilterableDropdown
                        v-model="selectedHotelId"
                        :items="this.hotels"
                        :name="$t('text_v1pb')"
                        textKey="name"
                        :localeKey="this.selectLocale"
                        idKey="id"
                        valueKey="id"
                        filterTargetKey="name"
                        filterTargetKanaKey="name_kana"
                        :loading="loading"
                        :disabled="loading"
                      />
                      <v-text-field
                        v-model="use_room_number"
                        :label="$t('text_5z91')"
                        :placeholder="$t('text_ulii6')"
                        maxlength="5"
                        @input="roomNumberValidate"
                      ></v-text-field>
                      <p
                        style="white-space: pre-wrap"
                        v-text="getHotelNote(selectedHotelId)"
                      />
                    </div>
                  </v-card>
                  <v-card flat v-else>
                    <p
                      style="
                        font-size: var(--read-font-size-s);
                        color: var(--read-font-color-sub);
                        font-weight: bold;
                      "
                    >
                      {{ $t('text_pyj4') }}
                    </p>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
        <div
          v-if="this.reserveId"
          style="
            font-size: var(--read-font-size-xs);
            font-weight: bold;
            color: var(--main-color);
          "
        >
          {{ $t('text_m4dd') }}
        </div>
      </div>
    </v-container>
    <v-divider></v-divider>

    <!-- <div
      v-if="
        medicalQuestionnaireServiceData.length !== 0 &&
          Object.keys(this.medicalQuestionnaireServiceData['serviceSet'])
            .length !== 0
      "
    >
      <div class="mt-3 pl-3">
        <v-icon
          size="3.5vh"
          style="color:var(--read-font-color-sub);padding-bottom:2px;"
          >mdi-clipboard-check-multiple-outline</v-icon
        >
        <div class="usr-call-step2-cond__header">問診票</div>
        <div
          style="font-size:var(--read-font-size-xs);font-weight:bold;color:var(--main-color);"
        >
          ※ご予約成立後に予約一覧からでも設定可能です。
        </div>
      </div>
      <div
        v-for="(serviceSet, setIndex) in this.medicalQuestionnaireServiceData[
          'serviceSet'
        ]"
        :key="serviceSet.id"
      >
        <v-container v-if="setIndex == 0">
          <div>
            <div
              class="usr-call-step2-cond__header"
              style="white-space:pre-line;"
            >
              {{ serviceSet.name }}
            </div>
            <div
              v-if="serviceSet.note !== null"
              :key="'note-' + serviceSet.id"
              style="display:flex;"
            >
              <div
                style="font-size:var(--read-font-size-xs);font-weight:bold;color:var(--main-color);"
              >
                ※
              </div>
              <div
                style="white-space:pre-line;font-size:var(--read-font-size-xs);font-weight:bold;color:var(--main-color);"
              >
                {{ serviceSet.note }}
              </div>
            </div>
          </div>
          <div>
            <v-radio-group
              v-model="
                medicalQuestionnaireServiceDataLabel['radio'][serviceSet.id]
              "
              row
            >
              <v-radio
                v-for="(service, index) in medicalQuestionnaireServiceData[
                  'service'
                ][serviceSet.id]"
                :key="index"
                :label="service.name"
                :value="service.id"
                style="width:50%;margin-right:0px;"
                @change="
                  changeMedicalQuestionnaireServiceAllDisplayFlag(service.name)
                "
              />
            </v-radio-group>
          </div>
          <v-divider></v-divider>
          <div
            @click="openDialogSetLatestMedicalQuestionnaireServiceRequests"
            v-if="
              medicalQuestionnaireServiceAllDisplayFlag &&
                medicalQuestionnaireServiceRequestsGetFlag
            "
          >
            <v-checkbox
              v-model="medicalQuestionnaireServiceRequestsSetFlag"
              class="ma-0"
              style="height:34px;"
              @change="toggleSetLatestMedicalQuestionnaireServiceRequests"
              :disabled="medicalQuestionnaireServiceRequestsLatest === ''"
            >
              <template v-slot:label>
                <span
                  class="font-weight-bold"
                  :style="
                    medicalQuestionnaireServiceRequestsLatest === ''
                      ? 'color:var(--read-font-color-sub)'
                      : 'color:var(--main-color)'
                  "
                  >前回このお店で指定したお好みを呼び出す</span
                >
              </template>
            </v-checkbox>
          </div>
          <v-divider></v-divider>
        </v-container>

        <v-container
          v-else-if="
            medicalQuestionnaireServiceAllDisplayFlag &&
              (serviceSet.type === 3 ||
                (medicalQuestionnaireServiceData['service'] &&
                  medicalQuestionnaireServiceData['service'][serviceSet.id]))
          "
          class="py-1"
        >
          <div>
            <div
              class="usr-call-step2-cond__header pb-2"
              style="white-space:pre-line;"
            >
              {{ serviceSet.name }}
            </div>
            <div
              v-if="serviceSet.note !== null"
              :key="'note-' + serviceSet.id"
              style="display:flex;"
            >
              <div
                style="font-size:var(--read-font-size-xs);font-weight:bold;color:var(--main-color);"
              >
                ※
              </div>
              <div
                style="white-space:pre-line;font-size:var(--read-font-size-xs);font-weight:bold;color:var(--main-color);"
              >
                {{ serviceSet.note }}
              </div>
            </div>
          </div>
          <div v-if="serviceSet.type === 1" class="pb-4">
            <v-col
              v-for="(service, index) in medicalQuestionnaireServiceData[
                'service'
              ][serviceSet.id]"
              :key="index"
              cols="6"
              class="my-0 pa-0"
              style="height:45px;font-weight:bold;display:inline-block;"
            >
              <v-checkbox
                v-model="
                  medicalQuestionnaireServiceDataLabel['radio'][serviceSet.id]
                "
                :label="service.name"
                :value="service.id"
                on-icon="mdi-radiobox-marked"
                off-icon="mdi-radiobox-blank"
                class="ma-0 pb-2"
              />
            </v-col>
          </div>
          <v-row v-if="serviceSet.type === 2" class="px-3 pb-4">
            <v-col
              v-for="(service, index) in medicalQuestionnaireServiceData[
                'service'
              ][serviceSet.id]"
              :key="index"
              cols="6"
              class="my-0 pa-0"
              style="height:45px;font-weight:bold;display:inline-block;"
            >
              <v-checkbox
                v-model="
                  medicalQuestionnaireServiceDataLabel['checkbox'][
                    serviceSet.id
                  ]
                "
                :label="service.name"
                :value="service.id"
                class="ma-0 pb-2"
              />
            </v-col>
          </v-row>
          <v-row v-if="serviceSet.type === 3" class="px-3 pb-8">
            <v-col
              cols="12"
              class="my-0 pa-0"
              style="height:45px;font-weight:bold;"
            >
              <v-text-field
                v-model="
                  medicalQuestionnaireServiceDataLabel['text'][serviceSet.id]
                "
                @focus="switchIsFocused(true)"
                @blur="switchIsFocused(false)"
                label="回答を入力"
                class="ma-0 pb-2"
              />
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </v-container>
      </div>
    </div> -->

    <v-container style="margin-bottom: 300px">
      <div class="my-2">
        <v-icon
          size="3.5vh"
          style="color: var(--read-font-color-sub); padding-bottom: 2px"
          >mdi-text-box-check</v-icon
        >
        <div class="usr-call-step2-cond__header">{{ $t('text_0g57') }}</div>
        <span
          style="
            color: var(--main-color);
            font-size: var(--read-font-size-s);
            font-weight: bold;
          "
          >{{ $t('required') }}</span
        >
      </div>
      <v-card outlined>
        <v-card-text
          style="white-space: pre-line; height: 300px; overflow-y: scroll"
          ref="termsRef"
          v-on:scroll="onScrollTerms"
        >
          <div v-html="useTerms"></div>
        </v-card-text>

        <v-card-actions>
          <v-checkbox
            v-model="useTermsCheck"
            :disabled="!isReadTerms"
            :label="$t('text_e4wt')"
          ></v-checkbox>
        </v-card-actions>
      </v-card>
    </v-container>
    <nav class="pa-2 cv-reserve-nav">
      <div class="mx-auto" style="max-width: 400px">
        <div v-if="Object.keys(this.castData).length === 0">
          <loading-image />
        </div>
        <div
          v-if="editReserveData && editReserveData.specialCardUnuseableFlag"
          class="mx-3 pa-2"
          style="
            font-size: var(--read-font-size-s);
            color: var(--main-color);
            font-weight: bold;
            background-color: #fcfcfc;
            border: 1px solid #ddd;
          "
        >
          {{ $t('text_ob8e') }}
        </div>
        <ServiceMenu
          v-if="isShowServiceMenu"
          :cast="this.castData"
          :cast_free="this.castFree"
          :course_money="Number(courseMoney)"
          :designation_money="Number(designationMoney)"
          :before_discount_designation_money="
            this.beforeDiscountDesignation.service_money
          "
          :cast_additional_money="Number(castAdditionalMoney)"
          :option_money="Number(optionMoney)"
          :transportation_money="Number(transportationMoney)"
          :extension_name="this.extensionName"
          :extension_money="Number(extensionMoney)"
          :tip_money="Number(0)"
          :discount_money="this.discountMoney"
          :point="this.reservePoint"
          :give_back_point="this.giveBackPoint"
          :total_money="Number(totalMoney) + calculateCardFeeMoney"
          :isPrivateFlag="
            isPrivateFlag(
              editReserveData['reservation']
                ? editReserveData['reservation']
                : ''
            )
          "
          :giveBackPointData="this.giveBackPointData"
        />
        <div class="mb-2 mx-3">
          <v-btn
            block
            :class="isRequireSelected ? '' : 'reserve-btn-disabled'"
            :color="isRequireSelected ? 'primary' : ''"
            style="font-size: var(--read-font-size-m); font-weight: bold"
            v-on:click.native="postReserve"
            >{{ postReserveBtnText }}
          </v-btn>
        </div>
      </div>
    </nav>
    <loading-save />
    <v-overlay v-if="reservationImpossible" opacity="0.6" />
    <v-dialog
      v-model="reservationImpossible"
      persistent
      no-click-animation
      max-width="350"
      transition="dialog-bottom-transition"
      style="z-index: 9999"
    >
      <v-card
        class="py-2 px-4"
        color="primary"
        style="color: #fff; font-size: var(--read-font-size-s)"
      >
        <v-row>
          <v-col class="pa-0" cols="2">
            <v-layout column fill-height>
              <v-container class="mt-4 pa-0 text-center">
                <img :src="alertImg" />
              </v-container>
            </v-layout>
          </v-col>
          <v-col class="px-1" cols="10">
            <div
              v-if="this.$store.state.dialog.reservationImpossibleMode == 1"
              class="pb-2"
            >
              {{ $t('text_k4fv') }}
              <div class="text-center">
                <v-btn class="my-3">
                  <a
                    :href="this.$store.state.bindCast.cast.reserveUrl"
                    style="text-decoration: none"
                    >{{ $t('text_2pr3') }}</a
                  >
                </v-btn>
              </div>
            </div>
            <div
              v-else-if="
                this.$store.state.dialog.reservationImpossibleMode == 2
              "
              class="pb-2"
            >
              {{ $t('text_p5cb') }}<br />
              {{ $t('text_o9oc') }}
            </div>
            <div
              v-else-if="
                this.$store.state.dialog.reservationImpossibleMode == 3
              "
              class="pb-2"
            >
              {{ $t('text_w0tg') }}<br />
              {{ $t('text_3n5o') }}
            </div>
            <div
              v-else-if="
                this.$store.state.dialog.reservationImpossibleMode == 4
              "
              class="pb-2"
            >
              {{ $t('text_hwz5') }}<br />
              {{ $t('text_7p7r') }}
            </div>
            <div v-else class="pb-2">
              {{ $t('text_azq7') }}<br /><br />

              {{ $t('text_1q8o') }}<br />
              {{ $t('text_duc8') }}<br /><br />

              ・{{ $t('text_bj9i') }}<br />
              ・{{ $t('text_2n7i') }}<br />
              ・{{ $t('text_s4ea') }}<br />
              ・{{ $t('text_ooh1') }}<br /><br />

              {{ $t('text_8gp2') }}
            </div>
          </v-col>
        </v-row>
        <div
          v-if="this.$store.state.castDialog.selectedCastReserveDialog"
          class="text-right"
        >
          <v-btn
            v-if="
              this.$store.state.dialog.reservationImpossibleMode == 3 ||
                this.$store.state.dialog.reservationImpossibleMode == 5
            "
            small
            @click="reservationImpossible = false"
            style="text-decoration: none"
            >{{ $t('close') }}</v-btn
          >
          <v-btn
            v-else
            small
            @click="closeCastDialog()"
            style="text-decoration: none"
            >{{ $t('back') }}</v-btn
          >
        </div>
        <div v-else class="text-right">
          <v-btn
            v-if="
              this.$store.state.dialog.reservationImpossibleMode == 3 ||
                this.$store.state.dialog.reservationImpossibleMode == 5
            "
            small
            @click="reservationImpossible = false"
            style="text-decoration: none"
            >{{ $t('close') }}</v-btn
          >
          <v-btn
            v-else
            small
            @click="$router.back()"
            style="text-decoration: none"
            >{{ $t('back') }}</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
    <!-- <v-dialog
      v-model="reservePointErrorMessage"
      persistent
      no-click-animation
      max-width="350"
      transition="dialog-bottom-transition"
      style="z-index:9999"
    >
      <v-card
        class="py-2 px-4"
        color="primary"
        style="color:#fff;font-size:var(--read-font-size-s);"
      >
        <v-row>
          <v-col class="pa-0" cols="2">
            <v-layout column fill-height>
              <v-container class="mt-4 pa-0 text-center">
                <v-icon color="white">mdi-alert</v-icon>
              </v-container>
            </v-layout>
          </v-col>
          <v-col class="px-1" cols="10">
            <div class="pb-2">
              ご利用金額以上のポイントは使用することができません。<br />
              使用ポイントのご確認をお願いします。
            </div>
          </v-col>
        </v-row>
        <div class="text-right">
          <v-btn
            small
            @click="reservePointErrorMessage = false"
            style="text-decoration:none;"
            >{{ $t('close') }}</v-btn
          >
        </div>
      </v-card>
    </v-dialog> -->
    <v-dialog
      v-model="errorDialog"
      persistent
      no-click-animation
      max-width="350"
      transition="dialog-bottom-transition"
      style="z-index: 9999"
    >
      <v-card
        class="py-2 px-4"
        color="primary"
        style="color: #fff; font-size: var(--read-font-size-s)"
      >
        <v-row>
          <v-col class="pa-0" cols="2">
            <v-layout column fill-height>
              <v-container class="mt-4 pa-0 text-center">
                <v-icon color="white">mdi-alert</v-icon>
              </v-container>
            </v-layout>
          </v-col>
          <v-col class="px-1" cols="10">
            <div class="pb-2">
              {{ $t('text_8xto') }}
            </div>
            <div>＜{{ $t('text_pph2') }}＞</div>
            <div>{{ $t('text_tei3') }}</div>
            <div>・{{ $t('text_l4f9') }}</div>
            <div>・{{ $t('text_qrv5') }}</div>
          </v-col>
        </v-row>
        <div
          v-if="this.$store.state.castDialog.selectedCastReserveDialog"
          class="text-right"
        >
          <v-btn
            small
            @click="closeCastDialog()"
            style="text-decoration: none"
            >{{ $t('back') }}</v-btn
          >
        </div>
        <div v-else class="text-right">
          <v-btn small
            ><nuxt-link
              :to="`/${$i18n.locale}/call`"
              style="text-decoration: none"
              >{{ $t('back') }}</nuxt-link
            ></v-btn
          >
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="shopHotelsErrorFlag"
      persistent
      no-click-animation
      max-width="350"
      transition="dialog-bottom-transition"
      style="z-index: 9998"
    >
      <v-card
        class="py-2 px-4"
        color="primary"
        style="color: #fff; font-size: var(--read-font-size-s)"
      >
        <v-row>
          <v-col class="pa-0" cols="2">
            <v-layout column fill-height>
              <v-container class="mt-4 pa-0 text-center">
                <v-icon color="white">mdi-alert</v-icon>
              </v-container>
            </v-layout>
          </v-col>
          <v-col class="px-1" cols="10">
            <div class="pb-2">
              {{ $t('text_9uts') }}<br />
              {{ $t('text_ju8i') }}
            </div>
          </v-col>
        </v-row>
        <div class="text-right">
          <v-btn
            small
            :loading="loading"
            :disabled="loading"
            v-on:click="getHotels()"
            >{{ $t('text_vim7') }}</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="ngAlertState"
      @click:outside="ngAlertState = false"
      persistent
      no-click-animation
      max-width="350"
      transition="dialog-bottom-transition"
      style="z-index: 9999"
    >
      <v-card
        class="py-2 px-4"
        color="primary"
        style="color: #fff; font-size: var(--read-font-size-s)"
      >
        <v-row>
          <v-col class="pa-0" cols="2">
            <v-layout column fill-height>
              <v-container class="mt-4 pa-0 text-center">
                <v-icon color="white">mdi-alert</v-icon>
              </v-container>
            </v-layout>
          </v-col>
          <v-col class="px-1" cols="10">
            <div class="pb-2">
              <!-- TODO:translate後回し -->
              恐れ入りますがご指定の{{
                ngAlertMessage
              }}ではご予約を承る事ができません。
            </div>
          </v-col>
        </v-row>
        <div class="text-right">
          <v-btn
            small
            @click="ngAlertState = false"
            style="text-decoration: none"
            >{{ $t('back') }}</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="reserveValidateError"
      @click:outside="reserveValidateError = false"
      persistent
      no-click-animation
      max-width="350"
      transition="dialog-bottom-transition"
      style="z-index: 9999"
    >
      <v-card
        class="py-2 px-4"
        color="primary"
        style="color: #fff; font-size: var(--read-font-size-s)"
      >
        <v-row>
          <v-col class="pa-0" cols="2">
            <v-layout column fill-height>
              <v-container class="mt-4 pa-0 text-center">
                <v-icon color="white">mdi-alert</v-icon>
              </v-container>
            </v-layout>
          </v-col>
          <v-col class="px-1" cols="10">
            <div class="pb-2">
              <div
                v-for="(errorMessage, index) in this
                  .reserveValidateErrorMessages"
                :key="index"
              >
                <div>{{ errorMessage }}</div>
              </div>
            </div>
          </v-col>
        </v-row>
        <div class="text-right">
          <v-btn
            small
            @click="reserveValidateError = false"
            style="text-decoration: none"
            >{{ $t('back') }}</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
    <!-- 過去問診要望の呼び出し不可モーダル -->
    <MedicalQuestionnaireServiceRequestsNoSetDialog
      ref="medicalQuestionnaireServiceRequestsNoSetDialog"
    />
  </v-main>
</template>

<script>
import axios from 'axios';
import ServiceMenu from '~/components/pages/call/ServiceMenu.vue';
import FilterableDropdown from '~/components/pages/call/FilterableDropdown.vue';
import MedicalQuestionnaireServiceRequestsNoSetDialog from '~/components/pages/dialog/MedicalQuestionnaireServiceRequestsNoSetDialog.vue';
import cardImg from '~/assets/img/obj-card2.png';
import alertImg from '~/assets/img/alert.png';
import IconPoint from '~/assets/img/icon-point.svg';
import IconTime from '~/assets/img/icon-time.svg';
import IconOption from '~/assets/img/icon-option.svg';
import IconRadiation from '~/assets/img/radiation.svg';
import { duration } from 'moment';
import Mixin from '../reserve/mixin';
import _ from 'lodash';
const moment = require('moment');

export default {
  components: {
    ServiceMenu,
    FilterableDropdown,
    MedicalQuestionnaireServiceRequestsNoSetDialog,
    IconPoint,
    IconTime,
    IconOption,
    IconRadiation,
  },
  data: () => ({
    castData: [], // キャストapi
    castFree: false,
    designateData: [], // 指名api
    beforeDiscountDesignation: [],
    extensionData: [], // 延長(スペシャルカード用)
    serviceData: [], // サービスapi
    siteReservationServiceData: [], // サイト予約サービスapi
    siteReservationServiceDataLabel: [], // サイト予約サービス(入力&選択)
    medicalQuestionnaireServiceData: [], // 問診票api
    medicalQuestionnaireServiceDataLabel: [], // 問診票(入力&選択)
    medicalQuestionnaireServiceAllDisplayFlag: false, // 問診票表示フラグ
    medicalQuestionnaireServiceRequestsLatest: [], // 直近の同一店舗 問診票サービス要望
    medicalQuestionnaireServiceRequestsGetFlag: false, // 直近の同一店舗 問診票サービス要望ゲットフラグ
    medicalQuestionnaireServiceRequestsSetFlag: false, // 直近の同一店舗 問診票サービス要望セットフラグ
    medicalQuestionnaireServiceRequestsSetDialogFlag: false, //過去に「お好み指定」をしたことがないお店だった場合に開くダイアログフラグ
    cardImg: cardImg, //クレジットカード画像パス
    castAdditionalMoney: 0,
    minCourseMinute: 0,
    courseName: '',
    courseMoney: 0,
    courseValue: '',
    useCourseMinute: 0,
    optionMoney: 0,
    optionValue: [],
    paymentMethodItems: {}, // お支払い方法 リスト
    paymentMethodValue: '', // お支払い方法 選択
    selectedTransportationMoney: [], // 住所オブジェクト(交通費)
    hotels: [], // ホテルリスト
    ngHotels: [], // NGホテルリスト
    selectedHotel: [], // 選択ホテル
    selectedHotelId: [], // 選択ホテルID
    hotelUndecidedTransportMoney: 1000, // ホテル未確定時デフォルト交通費
    homeTransportationMoney: 1000, // 自宅交通費
    use_address: '', // 利用場所の住所
    use_building: '', // 利用場所の物件名
    use_room_number: '', // 利用場所の部屋番号
    reservationImpossible: false, // 予約不可
    errorDialog: false,
    courseData: true,
    alertImg: alertImg,
    useTermsCheck: false,
    areaValue: '',
    upCategory: '',
    reserveId: '',
    editReserveData: [],
    editReserveSiteMemberId: '',
    reserveDateTimeMessage: false,
    willConfirmAt: '',
    shopHotelsErrorFlag: false,
    useSpecialCardId: null,
    loading: false,
    pointNote: '',
    giveBackPointData: [],
    giveBackPoint: 0,
    availablePoint: 0,
    reservePoint: 0,
    specifiedPoint: 0,
    discountName: '',
    discountMoney: 0,
    reservePointData: [],
    pointData: [
      { label: '全部使用する', value: 0 },
      { label: '一部使用する', value: 1 },
      { label: '使用しない', value: 2 },
    ],
    pointDataValue: 2,
    nicknameErrorMessage: false,
    disabledNextBtnPoint: false,
    disabledNextBtnRoomNumber: false,
    //reservePointErrorMessage: false,
    isFocused: false,
    ngAlertMessage: null,
    ngAlertState: false,
    reservationFromDs: false,
    cardFeeMoney: 0,
    shopCardFee: 0,
    reserveUpdatedAt: '',
    reserveValidateError: false,
    reserveValidateErrorMessages: [],
    isReadTerms: false,
    selectLocale: null
  }),
  mixins: [Mixin],
  computed: {
    hotelDefaultFeildText: function() {
      return this.$t('text_jx4h');
    },
    postReserveBtnText: function() {
      if (!this.reserveId) {
        return this.$t('text_qe20');
      } else {
        return this.$t('text_c36b');
      }
    },
    isShowServiceMenu: function() {
      if (this.isFocused) {
        return false;
      }
      return Object.keys(this.castData).length > 0;
    },
    reserveDateTime: function() {
      if (this.$store.state.call.arrivalDateTimeValue) {
        const messageTime = moment(new Date())
          .add(90, 'minute')
          .format('YYYY-MM-DD HH:mm:ss');
        if (messageTime <= this.$store.state.call.arrivalDateTimeValue) {
          this.reserveDateTimeMessage = true;
        }
        return this.$store.state.call.arrivalDateTimeValue;
      } else {
        // 今の時刻 + item.time 加算
        const dt = new Date();
        return moment(new Date())
          .add(this.$store.state.call.arrivalTimeValue, 'minute')
          .format('YYYY-MM-DD HH:mm:ss');
      }
    },
    designationName: function() {
      const desig = this.designateData;
      return desig ? desig.name : '';
    },
    designationMoney: function() {
      const desig = this.designateData;
      return desig ? desig.service_money : 0;
    },
    extensionName: function() {
      const extension = this.extensionData;
      return extension ? extension.name : '';
    },
    extensionMoney: function() {
      const extension = this.extensionData;
      return extension ? extension.service_money : 0;
    },
    optionLabel: function() {
      let optionLabel = [];
      let optionItems = this.serviceData['option'];
      let optionValue = this.optionValue;
      if (optionItems && optionValue) {
        optionItems.map(function(el) {
          optionValue.map(function(val) {
            if (el.id === val) {
              optionLabel.push(el.offical_site_display_name);
            }
          });
        });
      }
      return optionLabel.join('、');
    },
    postSiteMemberSiteReservationService: function() {
      let siteReservationService = [];
      siteReservationService['requests'] = [];
      if (this.siteReservationServiceData['sort']) {
        siteReservationService['memo'] = [];
        var i = 0;
        for (var key in this.siteReservationServiceData['sort']) {
          let setId = this.siteReservationServiceData['sort'][key];
          if (
            this.siteReservationServiceDataLabel['radio'] &&
            this.siteReservationServiceDataLabel['radio'][setId]
          ) {
            let radioId = this.siteReservationServiceDataLabel['radio'][setId];
            for (var serviceData in this.siteReservationServiceData['service'][
              setId
            ]) {
              if (
                this.siteReservationServiceData['service'][setId][serviceData][
                  'id'
                ] === radioId
              ) {
                siteReservationService['memo'].push(
                  this.siteReservationServiceData['service'][setId][
                    serviceData
                  ]['name']
                );
                siteReservationService['requests'][i] = {
                  setId: setId,
                  serviceId: radioId,
                };
                i += 1;
              }
            }
          } else if (
            this.siteReservationServiceDataLabel['checkbox'] &&
            this.siteReservationServiceDataLabel['checkbox'][setId]
          ) {
            for (var labelKey in this.siteReservationServiceDataLabel[
              'checkbox'
            ][setId]) {
              let checkboxServiceId = this.siteReservationServiceDataLabel[
                'checkbox'
              ][setId][labelKey];
              for (var serviceData in this.siteReservationServiceData[
                'service'
              ][setId]) {
                if (
                  this.siteReservationServiceData['service'][setId][
                    serviceData
                  ]['id'] === checkboxServiceId
                ) {
                  siteReservationService['memo'].push(
                    this.siteReservationServiceData['service'][setId][
                      serviceData
                    ]['name']
                  );
                  siteReservationService['requests'][i] = {
                    setId: setId,
                    serviceId: checkboxServiceId,
                  };
                  i += 1;
                }
              }
            }
          } else if (
            this.siteReservationServiceDataLabel['text'] &&
            this.siteReservationServiceDataLabel['text'][setId]
          ) {
            siteReservationService['memo'].push(
              this.siteReservationServiceDataLabel['text'][setId]
            );
            siteReservationService['requests'][i] = {
              setId: setId,
              serviceInfo: this.siteReservationServiceDataLabel['text'][setId],
            };
            i += 1;
          }
        }
        siteReservationService['memo'] = siteReservationService['memo'].join(
          '／'
        );
        return siteReservationService;
      } else {
        siteReservationService['memo'] = '';
        return siteReservationService;
      }
    },
    postMedicalQuestionnaireService: function() {
      let medicalQuestionnaireService = [];
      medicalQuestionnaireService['requests'] = [];
      if (this.medicalQuestionnaireServiceData['sort']) {
        medicalQuestionnaireService['memo'] = [];
        var i = 0;
        for (var key in this.medicalQuestionnaireServiceData['sort']) {
          let setId = this.medicalQuestionnaireServiceData['sort'][key];
          var set = this.medicalQuestionnaireServiceData['serviceSet'].find(
            (data) => data.id === setId
          );
          if (
            this.medicalQuestionnaireServiceDataLabel['radio'] &&
            this.medicalQuestionnaireServiceDataLabel['radio'][setId]
          ) {
            let radioId = this.medicalQuestionnaireServiceDataLabel['radio'][
              setId
            ];
            for (var serviceData in this.medicalQuestionnaireServiceData[
              'service'
            ][setId]) {
              if (
                this.medicalQuestionnaireServiceData['service'][setId][
                  serviceData
                ]['id'] === radioId
              ) {
                if (this.medicalQuestionnaireServiceAllDisplayFlag == true) {
                  medicalQuestionnaireService['memo'].push(
                    '【' +
                      set['name'] +
                      '】\n' +
                      this.medicalQuestionnaireServiceData['service'][setId][
                        serviceData
                      ]['name']
                  );
                  medicalQuestionnaireService['requests'][i] = {
                    setId: setId,
                    serviceId: radioId,
                  };
                  i += 1;
                } else if (
                  this.medicalQuestionnaireServiceAllDisplayFlag == false &&
                  set['short_name'] == this.$t('text_693f')
                ) {
                  medicalQuestionnaireService['memo'].push(
                    '【' +
                      set['name'] +
                      '】\n' +
                      this.medicalQuestionnaireServiceData['service'][setId][
                        serviceData
                      ]['name']
                  );
                  medicalQuestionnaireService['requests'][i] = {
                    setId: setId,
                    serviceId: radioId,
                  };
                  i += 1;
                }
              }
            }
          } else if (
            this.medicalQuestionnaireServiceDataLabel['checkbox'] &&
            this.medicalQuestionnaireServiceDataLabel['checkbox'][setId]
          ) {
            let title = '';
            let memo = [];
            for (var labelKey in this.medicalQuestionnaireServiceDataLabel[
              'checkbox'
            ][setId]) {
              let checkboxServiceId = this.medicalQuestionnaireServiceDataLabel[
                'checkbox'
              ][setId][labelKey];
              for (var serviceData in this.medicalQuestionnaireServiceData[
                'service'
              ][setId]) {
                if (
                  this.medicalQuestionnaireServiceData['service'][setId][
                    serviceData
                  ]['id'] === checkboxServiceId
                ) {
                  if (this.medicalQuestionnaireServiceAllDisplayFlag == true) {
                    title = set['name'];
                    memo.push(
                      this.medicalQuestionnaireServiceData['service'][setId][
                        serviceData
                      ]['name']
                    );
                    medicalQuestionnaireService['requests'][i] = {
                      setId: setId,
                      serviceId: checkboxServiceId,
                    };
                    i += 1;
                  }
                }
              }
            }
            if (title != '') {
              medicalQuestionnaireService['memo'].push(
                '【' + title + '】\n' + memo.join('、')
              );
            }
          } else if (
            this.medicalQuestionnaireServiceDataLabel['text'] &&
            this.medicalQuestionnaireServiceDataLabel['text'][setId]
          ) {
            if (this.medicalQuestionnaireServiceAllDisplayFlag == true) {
              medicalQuestionnaireService['memo'].push(
                '【' +
                  set['name'] +
                  '】\n' +
                  this.medicalQuestionnaireServiceDataLabel['text'][setId]
              );
              medicalQuestionnaireService['requests'][i] = {
                setId: setId,
                serviceInfo: this.medicalQuestionnaireServiceDataLabel['text'][
                  setId
                ],
              };
              i += 1;
            }
          }
        }
        medicalQuestionnaireService['memo'] = medicalQuestionnaireService[
          'memo'
        ].join('\n');
        return medicalQuestionnaireService;
      } else {
        medicalQuestionnaireService['memo'] = '';
        return medicalQuestionnaireService;
      }
    },
    transportationMoney: function() {
      // ホテル交通費
      if (this.$store.state.siteMemberData.selectPlace === 'selected-hotel') {
        const hotel = this.hotels.find(
          (data) => data.id === this.selectedHotelId
        );
        if (typeof hotel === 'undefined') {
          return this.hotelUndecidedTransportMoney;
        }
        this.selectedHotel = hotel;
        return hotel.transportation_money;

        // 自宅交通費
      } else if (
        this.$store.state.siteMemberData.selectPlace === 'selected-home'
      ) {
        return this.homeTransportationMoney;
      }
    },
    makeServiceData: function() {
      let obj = [];
      // 指名
      if (this.designateData) {
        obj.push(this.designateData.id);
      }
      // コース
      obj.push(this.courseValue);
      // オプション
      const opObj = this.optionValue;
      Object.keys(opObj).forEach((key) => {
        obj.push(opObj[key]);
      });
      // 延長(スペシャルカード使用時のみ)
      if (this.useSpecialCardId && this.extensionData) {
        obj.push(this.extensionData.id);
      }
      return obj;
    },
    totalMoney: function() {
      var money =
        Number(this.courseMoney) +
        Number(this.designationMoney) +
        Number(this.castAdditionalMoney) +
        Number(this.optionMoney) +
        Number(this.transportationMoney) -
        Number(this.discountMoney);

      /*
      if (this.pointDataValue == 0 && this.availablePoint > money) {
        this.reservePoint = money;
      } else if (this.pointDataValue == 0 && this.availablePoint < money) {
        var availablePoint = this.availablePoint;
        this.reservePoint = availablePoint;
      }
      // 還元予定ポイント
      if (Object.keys(this.giveBackPointData).length != 0) {
        this.changeGiveBackPointPoint(money);
      }
      */

      return Number(money) - Number(this.reservePoint);
    },
    disabledNextBtn: function() {
      // ポイント関連
      if (
        Number(this.totalMoney) < 0 ||
        (this.paymentMethodValue == 1 && Number(this.totalMoney) == 0) ||
        (this.paymentMethodValue == 2 &&
          Number(this.totalMoney) != 0 &&
          Number(this.reservePoint) % 1000 != 0)
      ) {
        // 総額が0より小さい
        // カード決済かつ総額が0
        // 現金決済かつ総額が0でないかつ1000P単位でない
        this.disabledNextBtnPoint = false;
      } else if (
        Number(this.reservePoint) == 0 ||
        (this.paymentMethodValue == 2 && Number(this.totalMoney) == 0) ||
        (this.paymentMethodValue == 1 && Number(this.totalMoney) > 0) ||
        (this.paymentMethodValue == 2 &&
          Number(this.totalMoney) != 0 &&
          Number(this.reservePoint) % 1000 == 0)
      ) {
        // ポイント未使用
        // 現金決済かつ総額が0
        // カード決済かつ総額が1以上
        // 現金決済かつ総額が0でないかつ1000P単位
        this.disabledNextBtnPoint = true;
      } else {
        // 例外
        this.disabledNextBtnPoint = false;
      }

      if (!this.use_room_number) {
        this.disabledNextBtnRoomNumber = true;
      } else if (this.use_room_number.replace(/-/g, '')) {
        this.disabledNextBtnRoomNumber = true;
      } else {
        this.disabledNextBtnRoomNumber = false;
      }
      return !this.reservationImpossible &&
        this.paymentMethodValue &&
        this.courseValue &&
        this.useTermsCheck === true &&
        this.shopHotelsErrorFlag == false &&
        this.disabledNextBtnPoint === true &&
        this.disabledNextBtnRoomNumber === true
        ? false
        : true;
    },
    readonlyPointRadio: function() {
      if (this.reserveId && !this.editReserveSiteMemberId) {
        return true;
      } else if (this.discountMoney > 0) {
        return true;
      } else if (this.availablePoint <= 0) {
        return true;
      } else {
        return false;
      }
    },
    disabledPointInput: function() {
      if (this.reserveId && !this.editReserveSiteMemberId) {
        return true;
      } else if (this.discountMoney > 0) {
        return true;
      } else if (this.pointDataValue == 1) {
        return false;
      } else {
        return true;
      }
    },
    calculateCardFeeMoney: function() {
      // DSで作成された予約でクレジットカード決済を選択した場合は手数料を計算
      if (this.reservationFromDs && this.paymentMethodValue == 1) {
        this.cardFeeMoney =
          Number(this.totalMoney) * (Number(this.shopCardFee) * 0.01);
        return this.cardFeeMoney;
      }
      this.cardFeeMoney = 0;
      return this.cardFeeMoney;
    },
    isRequireSelected: function() {
      return (
        this.courseValue !== '' &&
        this.paymentMethodValue !== '' &&
        this.useTermsCheck
      );
    },
    useTerms: function() {
      return this.castData.shop.use_terms.replace(
        /^(.*?)(?=2\. )/s,
        '<span style="color:red; font-weight:bold;">$1</span>'
      );
    },
  },
  watch: {
    courseValue: function(newValue, oldValue) {
      // courseMoney 計算
      if (this.serviceData) {
        let courseItems = this.serviceData['course'];
        if (courseItems !== 'undefined' && newValue) {
          let target = courseItems.find((item) => {
            return item['id'] === newValue;
          });
          if (target) {
            this.courseMoney = target.service_money;
            this.courseName = target.name;
            this.useCourseMinute = target.minute;
          }
        }
      }
    },
    optionValue: function(newValue, oldValue) {
      // optionMoney 計算
      if (this.serviceData) {
        let optionItems = this.serviceData['option'];
        let money = 0;
        if (newValue && optionItems) {
          optionItems.map(function(el) {
            newValue.map(function(val) {
              if (el.id === val) {
                money += el.service_money;
              }
            });
          });
        }
        this.optionMoney = money;
      }
    },
    selectedHotelId: function() {
      var hotel = this.hotels.find((data) => data.id === this.selectedHotelId);
      if (hotel !== undefined) {
        this.areaValue = hotel['area_id'];
      }
    },
    pointDataValue: function() {
      if (this.pointDataValue == 0) {
        var availablePoint = this.availablePoint;
        this.reservePoint = availablePoint;
      } else if (this.pointDataValue == 1) {
        var specifiedPoint = this.specifiedPoint;
        this.reservePoint = specifiedPoint;
      } else if (this.pointDataValue == 2) {
        this.reservePoint = 0;
      }
    },
    paymentMethodValue: function() {
      this.calculateCardFeeMoney;

      // 使用ポイントの選択変更
      if (this.reserveId && !this.editReserveSiteMemberId) {
        // Tokyo Escort OTOME予約でない
        this.pointNote =
          '※ポイントの使用はTokyo Escort OTOME予約のみとなります。';
      } else if (this.discountMoney > 0) {
        this.pointNote =
          '※割引が適用されている為、使用ポイントの変更はできません。';
      } else if (this.paymentMethodValue == 1 && this.availablePoint > 0) {
        // カード
        this.pointNote = '';
        this.pointDataValue = 0;
      } else if (this.paymentMethodValue == 2 && this.availablePoint >= 1000) {
        // 現金
        this.pointNote =
          '※現金決済の場合1000P単位でのご使用となります。自動的に最大ポイントが使用されます。';
        var maxUsePoint = Math.floor(this.availablePoint / 1000) * 1000;
        var moneyData =
          Number(this.courseMoney) +
          Number(this.designationMoney) +
          Number(this.castAdditionalMoney) +
          Number(this.optionMoney) +
          Number(this.transportationMoney) -
          Number(this.discountMoney);

        if (maxUsePoint >= moneyData) {
          this.specifiedPoint = Math.floor(moneyData / 1000) * 1000;
        } else {
          this.specifiedPoint = maxUsePoint;
        }
        this.pointDataValue = 1;
      } else {
        if (this.paymentMethodValue == 1) {
          this.pointNote = '';
        } else if (this.paymentMethodValue == 2) {
          this.pointNote =
            '※現金決済の場合1000P単位でのご使用となります。現在1000P未満の為ポイントを使用出来ません。';
        }
        this.pointDataValue = 2;
      }
    },
  },
  created: async function() {
    this.castFree = this.$store.state.call.castFree;
    if (
      !this.$store.state.siteMemberData.nickname.match(
        /[a-zA-Z0-9ぁ-んーァ-ヴー]/
      )
    ) {
      this.nicknameErrorMessage = true;
      this.loading = false;
    } else {
      if (
        this.$store.state.siteMemberData.selectPlace &&
        this.$store.state.siteMemberData.selectPlace == 'selected-home'
      ) {
        this.areaValue = this.$store.state.siteMemberData.home_area_id;
      } else if (
        this.$store.state.siteMemberData.selectPlace &&
        this.$store.state.siteMemberData.selectPlace == 'selected-hotel'
      ) {
        this.areaValue = this.$store.state.siteMemberData.area_ids;
      } else {
        if (
          this.$store.state.siteMemberData.last_use_place_search_type == 1 &&
          this.$store.state.siteMemberData.home_hope_flag == 1
        ) {
          this.areaValue = this.$store.state.siteMemberData.home_area_id;
        } else {
          this.areaValue = this.$store.state.siteMemberData.area_ids;
        }
      }
      // 指名キャストがstoreから外れた時はリダイレクト
      if (!this.$store.state.call.nominateCast) {
        this.$router.push(`/${$i18n.locale}/call`);
      }
      this.reserveId = this.$store.state.call.selectedReserveId;
      /*
      if (this.reserveId) {
        this.postReserveBtnText = this.$t('text_c36b');
      }
      */
      this.checkResrvable(); // 予約可能か確認
      this.paymentMethodItems = this.$t('payment_method_items');

      this.getDesignate();
      await this.getServices();
      this.getSiteReservationServices();
      this.getMedicalQuestionnaireServices();
      //this.getHotels();
      this.getCastNgHotels(this.$store.state.call.nominateCast, this.areaValue);
      this.getHomeTransportationMoney();
      this.getEditReserveData(this);
    }
    this.getCast();
  },
  methods: {
    onScrollTerms(e) {
      if (typeof window === 'undefined') return;
      const reference = this.$refs.termsRef;
      const top = e.target.scrollTop;
      const maxY = reference.scrollHeight - reference.clientHeight;
      if (top > (maxY-5)) {
        this.isReadTerms = true;
      }
    },
    switchIsFocused: function(isFocused) {
      console.log('otherRequireFocus pass:', isFocused);
      this.isFocused = isFocused;
    },
    /**
     * castData
     */
    getCast: function() {
      this.$axios
        .post(
          'private/cast-one',
          {
            sitePublicFlag: 1,
            castId: this.$store.state.call.nominateCast,
            arrivalDateTimeValue: this.$store.state.call.arrivalDateTimeValue, // 何時何分
            arrivalTimeValue: this.$store.state.call.arrivalTimeValue, // 何分後
            siteMemberTel: this.$store.state.siteMemberData.tel,
          },
          {
            headers: {
              Authorization: this.$auth.$storage.getUniversal(
                '_token.auth0',
                true
              ),
            },
          }
        )
        .then((response) => {
          this.castData = response.data;
          this.upCategory = this.castData.cast_schedules
            ? this.castData.cast_schedules[0].up_category
            : '';
          if (response.data) {
            this.castAdditionalMoney = this.castData['cast_additional_money'];
          }
        });
    },
    /**
     * designateData
     */
    getDesignate: function() {
      this.$axios
        .post(
          'private/cast-designate-charge',
          {
            castId: this.$store.state.call.nominateCast,
            arrivalTimeValue: this.$store.state.call.arrivalTimeValue,
            arrivalDateTimeValue: this.$store.state.call.arrivalDateTimeValue,
            siteMemberId: this.$store.state.siteMemberData.id,
            reserveId: Number(this.reserveId),
            castFreeFlag: this.castFree ? 1 : 0,
          },
          {
            headers: {
              Authorization: this.$auth.$storage.getUniversal(
                '_token.auth0',
                true
              ),
            },
          }
        )
        .then((response) => {
          this.designateData = response.data['designation'][0];
          if (
            response.data['use_special_card_id'] &&
            response.data['before_discount_designation']
          ) {
            this.beforeDiscountDesignation =
              response.data['before_discount_designation'][0];
            this.useSpecialCardId = response.data['use_special_card_id'];
          }
          if (
            response.data['use_special_card_id'] &&
            response.data['special_card_extension']
          ) {
            this.extensionData = response.data['special_card_extension'][0];
            this.useSpecialCardId = response.data['use_special_card_id'];
          }
        })
        .catch((error) => {});
    },
    /**
     * serviceData
     */
    getServices: async function() {
      await this.$axios
        .post(
          'private/cast-services2',
          {
            arrivalDateTimeValue: this.$store.state.call.arrivalDateTimeValue,
            castId: this.$store.state.call.nominateCast,
            areaId: this.areaValue,
            siteMemberId: this.$store.state.siteMemberData.id,
            selectPlace: this.$store.state.siteMemberData.selectPlace,
            selectHotelId: this.$store.state.siteMemberData.selectedHotelId,
            homeAreaId: this.$store.state.siteMemberData.home_area_id,
            homeSubAreaId: this.$store.state.siteMemberData.home_sub_area_id,
            exceptReserveId: Number(this.reserveId), // 予約変更
          },
          {
            headers: {
              Authorization: this.$auth.$storage.getUniversal(
                '_token.auth0',
                true
              ),
            },
          }
        )
        .then((response) => {
          this.serviceData = response.data;
          if (this.serviceData.option.length) {
            this.serviceData.option.forEach((item) => {
              if (item.offical_site_display_name == 'OF') {
                item.offical_site_display_name = item.name;
              }
            });
          }
          if (response.data.minCourseMinute) {
            this.minCourseMinute = response.data.minCourseMinute;
          }
        });
    },
    /**
     * siteReservationServiceData
     */
    getSiteReservationServices: function() {
      this.$axios
        .post(
          'private/shop-site-reservation-services',
          {
            castId: this.$store.state.call.nominateCast,
          },
          {
            headers: {
              Authorization: this.$auth.$storage.getUniversal(
                '_token.auth0',
                true
              ),
            },
          }
        )
        .then((response) => {
          this.siteReservationServiceData = response.data;
          this.siteReservationServiceDataLabel = response.data.post;
        });
    },
    /**
     * medicalQuestionnaireServiceData
     */
    getMedicalQuestionnaireServices: function() {
      this.$axios
        .post(
          'private/shop-medical-questionnaire-services',
          {
            castId: this.$store.state.call.nominateCast,
          },
          {
            headers: {
              Authorization: this.$auth.$storage.getUniversal(
                '_token.auth0',
                true
              ),
            },
          }
        )
        .then((response) => {
          this.medicalQuestionnaireServiceData = response.data;
          this.medicalQuestionnaireServiceDataLabel = response.data.post;
        });
    },
    /**
     * ホテル・交通費 Item取得
     */
    getHotels: function() {
      this.loading = true;
      let shopHotelsAPI = 'private/shop-hotels-by-time-zones';
      let transportationMoneyReferTime = this.$moment(
        this.$store.state.call.arrivalDateTimeValue
      ).format('HH:mm:ss');
      if (
        Object.keys(this.editReserveData).length != 0 &&
        Object.keys(this.editReserveData.reservation).length != 0 &&
        this.editReserveData.reservation.start_datetime ==
          this.$store.state.call.arrivalDateTimeValue &&
        this.editReserveData.reservation.transportation_money_refer_time
      ) {
        transportationMoneyReferTime = this.editReserveData.reservation
          .transportation_money_refer_time;
      }

      this.$axios
        .post(
          shopHotelsAPI,
          {
            castId: this.$store.state.call.nominateCast,
            areaId: this.areaValue,
            transportationMoneyReferTime: transportationMoneyReferTime,
          },
          {
            headers: {
              Authorization: this.$auth.$storage.getUniversal(
                '_token.auth0',
                true
              ),
            },
          }
        )
        .then(async (response) => {
          if (this.$i18n.locale === 'zh-cn') {
            this.selectLocale = 'zh_cn'
          } else if (this.$i18n.locale === 'zh-tw') {
            this.selectLocale = 'zh_tw'
          } else if (this.$i18n.locale === 'ko') {
            this.selectLocale = 'ko'
          } else {
            this.selectLocale = 'en'
          }
          if (response.data) {
            this.hotels = response.data.otherHotels;
            // シングルクォーテーションの文字化け対応
            this.hotels.forEach(
              (item) => (item.name = this.unescapeString(item.name))
            );
            //// ホテル指定あり
            if (this.$store.state.siteMemberData.selectedHotelId) {
              this.selectedHotel = this.hotels.find(
                (v) => v.id === this.$store.state.siteMemberData.selectedHotelId
              );
            }
            //// ホテルがエリア内に存在するかどうか
            if (this.selectedHotel) {
              this.selectedHotelId = this.selectedHotel.id;
            }
            //// 「(未定)○○駅周辺のホテル」をデフォルトにする
            if (
              !this.$store.state.siteMemberData.selectedHotelId ||
              !this.selectedHotel
            ) {
              const defaultHotel = this.hotels.find((v) =>
                v['name_'+this.selectLocale].match(
                  new RegExp(this.$t('undecided'))
                )
              );
              if (defaultHotel) {
                this.selectedHotel = defaultHotel;
                this.selectedHotelId = this.selectedHotel.id;
              } else if (!defaultHotel && this.hotels.length > 0) {
                const def = this.hotels.find((v) =>
                  v['name_'+this.selectLocale].match(
                    new RegExp(this.$t('undecided'))
                  )
                );
                if (def) {
                  this.selectedHotel = def;
                  this.selectedHotelId = def.id;
                }
              }
            }
            this.shopHotelsErrorFlag = false;
          }
        })
        .catch((error) => {
          this.shopHotelsErrorFlag = true;
        })
        .finally((res) => {
          this.loading = false;
        });
    },
    /**
     * キャストNGホテル
     */
    getCastNgHotels: function() {
      this.$axios
        .post(
          `private/cast-ng-hotels`,
          {
            castId: this.$store.state.call.nominateCast,
            areaId: this.areaValue,
          },
          {
            headers: {
              Authorization: this.$auth.$storage.getUniversal(
                '_token.auth0',
                true
              ),
            },
          }
        )
        .then(async (response) => {
          if (response.data) {
            this.ngHotels = response.data;
          }
        })
        .catch((error) => {});
    },
    /**
     * 自宅交通費取得
     */
    getHomeTransportationMoney: function() {
      let homeTransportationMoneyAPI =
        'private/shop-sub-area-home-transportation-money';
      let transportationMoneyReferTime = this.$moment(
        this.$store.state.call.arrivalDateTimeValue
      ).format('HH:mm:ss');
      if (
        Object.keys(this.editReserveData).length != 0 &&
        Object.keys(this.editReserveData.reservation).length != 0 &&
        this.editReserveData.reservation.start_datetime ==
          this.$store.state.call.arrivalDateTimeValue &&
        this.editReserveData.reservation.transportation_money_refer_time
      ) {
        transportationMoneyReferTime = this.editReserveData.reservation
          .transportation_money_refer_time;
      }

      this.$axios
        .post(
          homeTransportationMoneyAPI,
          {
            castId: this.$store.state.call.nominateCast,
            areaId: this.areaValue,
            subAreaId: this.$store.state.siteMemberData.home_sub_area_id,
            transportationMoneyReferTime: transportationMoneyReferTime,
          },
          {
            headers: {
              Authorization: this.$auth.$storage.getUniversal(
                '_token.auth0',
                true
              ),
            },
          }
        )
        .then((response) => {
          this.homeTransportationMoney =
            response.data.home_transportation_money;
        });
    },
    /**
     * ポイント取得
     */
    /*
    getReserveSiteMemberPoint: function() {
      let reservationStartDt = this.$moment(
        this.$store.state.call.arrivalDateTimeValue
      ).format('HH:mm:ss');
      if (
        Object.keys(this.editReserveData).length != 0 &&
        Object.keys(this.editReserveData.reservation).length != 0 &&
        this.editReserveData.reservation.start_datetime ==
          this.$store.state.call.arrivalDateTimeValue &&
        this.editReserveData.reservation.transportation_money_refer_time
      ) {
        reservationStartDt = this.editReserveData.reservation
          .transportation_money_refer_time;
      }
      this.$axios
        .post(
          'private/get-reserve-site-member-point',
          {
            email: `${this.$store.state.auth.user.email}`,
            arrivalDateTimeValue: this.$store.state.call.arrivalDateTimeValue,
            arrivalTimeValue: this.$store.state.call.arrivalTimeValue,
            reservationStartDt: reservationStartDt,
            reserveId: this.reserveId,
            castId: this.$store.state.call.nominateCast,
            castFreeFlag: this.castFree ? 1 : 0,
          },
          {
            headers: {
              Authorization: this.$auth.$storage.getUniversal(
                '_token.auth0',
                true
              ),
            },
          }
        )
        .then((response) => {
          if (
            Object.keys(this.editReserveData).length == 0 ||
            (Object.keys(this.editReserveData).length != 0 &&
              this.editReserveData['reservation']['site_member_id'])
          ) {
            this.giveBackPointData = response.data.giveBackPoint;
          }
          this.availablePoint = response.data.availablePoint;
          this.reservePoint = response.data.reservePoint;
          this.reservePointData = response.data.reservePointData;
          if (
            this.reservePoint != 0 &&
            this.reservePoint == this.availablePoint
          ) {
            this.pointDataValue = 0;
          } else if (
            this.reservePoint != 0 &&
            this.reservePoint < this.availablePoint
          ) {
            this.pointDataValue = 1;
            this.specifiedPoint = response.data.reservePoint;
          }
        });
    },
    */
    /*
    changeGiveBackPointPoint: function(money) {
      this.giveBackPoint =
        (Number(money) - Number(this.reservePoint)) *
        this.giveBackPointData.reduction_rate;
      if (Object.keys(this.giveBackPointData.detail).length != 0) {
        let detail = this.giveBackPointData.detail;
        let courseDisplay = {
          course: { dataKey: null, point: 0 },
          firstTime: { dataKey: null, point: 0 },
          newCast: { dataKey: null, point: 0 },
          newDaysAgo: { dataKey: null, point: 0 },
          newTimeRange: { dataKey: null, point: 0 },
          newFree: { dataKey: null, point: 0 },
          cast: { dataKey: null, point: 0 },
        };
        // 選択コースによる特定コース・新規・新人の還元予定ポイント
        if (this.courseValue) {
          var courseId = this.courseValue;
          var courseMinute = this.useCourseMinute;
          var designationName = this.designationName;

          detail.forEach(function(data, key) {
            if (
              data.setName &&
              ((data.setName == 'course' &&
                this.giveBackPointData.course.length != 0) ||
                (data.setName == 'firstTime' &&
                  this.giveBackPointData.firstTime.length != 0) ||
                (data.setName == 'newCast' &&
                  this.giveBackPointData.newCast.length != 0) ||
                (data.setName == 'newDaysAgo' &&
                  this.giveBackPointData.newDaysAgo.length != 0) ||
                (data.setName == 'newTimeRange' &&
                  this.giveBackPointData.newTimeRange.length != 0) ||
                (data.setName == 'newFree' &&
                  this.giveBackPointData.newFree.length != 0) ||
                (data.setName == 'cast' &&
                  this.giveBackPointData.cast.length != 0))
            ) {
              data.displayFlag = false;
            }
          }, this);
          detail.forEach(function(data, key) {
            if (
              this.coursePointDuplicated(
                data,
                courseId,
                courseMinute,
                designationName,
                courseDisplay
              )
            ) {
              data.displayFlag = true;
              if (data.setName == 'course') {
                if (courseDisplay.course.dataKey)
                  detail[courseDisplay.course.dataKey].displayFlag = false;
                courseDisplay.course = { dataKey: key, point: data.point };
              } else if (data.setName == 'firstTime') {
                if (courseDisplay.firstTime.dataKey)
                  detail[courseDisplay.firstTime.dataKey].displayFlag = false;
                courseDisplay.firstTime = { dataKey: key, point: data.point };
              } else if (data.setName == 'newCast') {
                if (courseDisplay.newCast.dataKey)
                  detail[courseDisplay.newCast.dataKey].displayFlag = false;
                courseDisplay.newCast = { dataKey: key, point: data.point };
              } else if (data.setName == 'newDaysAgo') {
                if (courseDisplay.newDaysAgo.dataKey)
                  detail[courseDisplay.newDaysAgo.dataKey].displayFlag = false;
                courseDisplay.newDaysAgo = { dataKey: key, point: data.point };
              } else if (data.setName == 'newTimeRange') {
                if (courseDisplay.newTimeRange.dataKey)
                  detail[
                    courseDisplay.newTimeRange.dataKey
                  ].displayFlag = false;
                courseDisplay.newTimeRange = {
                  dataKey: key,
                  point: data.point,
                };
              } else if (data.setName == 'newFree') {
                if (courseDisplay.newFree.dataKey)
                  detail[courseDisplay.newFree.dataKey].displayFlag = false;
                courseDisplay.newFree = { dataKey: key, point: data.point };
              } else if (data.setName == 'cast') {
                if (courseDisplay.cast.dataKey)
                  detail[courseDisplay.cast.dataKey].displayFlag = false;
                courseDisplay.cast = { dataKey: key, point: data.point };
              }
            }
          }, this);
        }
        // 選択オプションによる還元予定ポイント
        if (
          this.optionValue.length != 0 &&
          this.giveBackPointData.option.length != 0
        ) {
          detail.forEach(function(option, key) {
            if (option.setName && option.setName == 'option') {
              option.displayFlag = false;
            }
          });

          this.optionValue.map(function(val) {
            detail.forEach(function(option, key) {
              if (
                option.serviceId &&
                option.setName &&
                option.setName == 'option' &&
                option.serviceId == val
              ) {
                option.displayFlag = true;
              }
            });
          });
        }

        let onConcurrent = detail.reduce(
          (sum, i) =>
            (i.defaultFlag || i.displayFlag) && i.concurrentUseFlag == 1
              ? sum + i.point
              : sum,
          0
        );
        let offConcurrent = detail.reduce(
          (sum, i) =>
            (i.defaultFlag || i.displayFlag) &&
            i.concurrentUseFlag == 0 &&
            i.point > sum
              ? i.point
              : sum,
          0
        );
        let offConcurrentCount = 0;
        detail.forEach(function(data, key) {
          if (offConcurrent >= onConcurrent && data.concurrentUseFlag == 1) {
            data.displayFlag = false;
          } else if (
            offConcurrent >= onConcurrent &&
            data.concurrentUseFlag != 1
          ) {
            if (data.point == offConcurrent && offConcurrentCount == 0) {
              data.displayFlag = true;
              offConcurrentCount += 1;
            } else {
              data.displayFlag = false;
            }
          } else if (
            onConcurrent > offConcurrent &&
            data.concurrentUseFlag == 0
          ) {
            data.displayFlag = false;
          } else if (
            onConcurrent > offConcurrent &&
            data.concurrentUseFlag != 0 &&
            data.defaultFlag
          ) {
            data.displayFlag = true;
          }
        });

        if (offConcurrent >= onConcurrent) {
          this.giveBackPoint += offConcurrent;
        } else {
          this.giveBackPoint += onConcurrent;
        }
      }

      return true;
    },
    */
    /*
    coursePointDuplicated: function(
      data,
      courseId,
      courseMinute,
      designationName,
      courseDisplay
    ) {
      if (
        data.serviceId &&
        data.setName &&
        data.serviceId == courseId &&
        ((data.setName == 'course' &&
          data.point > courseDisplay.course.point &&
          (data.asDesignatePoint == 0 ||
            !designationName.match(new RegExp(this.$t('designated'))))) ||
          (data.setName == 'firstTime' &&
            data.point > courseDisplay.firstTime.point &&
            (data.asDesignatePoint == 0 ||
              !designationName.match(new RegExp(this.$t('designated'))))) ||
          (data.setName == 'newCast' &&
            data.point > courseDisplay.newCast.point &&
            (data.asDesignatePoint == 0 ||
              !designationName.match(new RegExp(this.$t('designated'))))) ||
          (data.setName == 'newDaysAgo' &&
            data.point > courseDisplay.newDaysAgo.point &&
            (data.asDesignatePoint == 0 ||
              !designationName.match(new RegExp(this.$t('designated'))))) ||
          (data.setName == 'newTimeRange' &&
            data.point > courseDisplay.newTimeRange.point &&
            (data.asDesignatePoint == 0 ||
              !designationName.match(new RegExp(this.$t('designated'))))) ||
          (data.setName == 'newFree' &&
            data.point > courseDisplay.newFree.point) ||
          (data.setName == 'cast' &&
            data.point > courseDisplay.cast.point &&
            (data.asDesignatePoint == 0 ||
              !designationName.match(new RegExp(this.$t('designated'))))))
      ) {
        return true;
      } else if (
        data.minutes &&
        courseMinute >= data.minutes &&
        (!data.asDesignatePoint ||
          data.asDesignatePoint == 0 ||
          !designationName.match(new RegExp(this.$t('designated'))))
      ) {
        return true;
      } else {
        return false;
      }
    },
    */
    /**
     * ホテル注意事項
     */
    getHotelNote: function(id) {
      if (id && this.hotels.length !== 0) {
        var hotelList = this.hotels;
        var note;
        hotelList.map(function(el) {
          if (el['id'] === id) {
            note = el['note'];
          }
        });
        return note;
      } else {
        return '';
      }
    },
    /**
     * 予約情報（変更用）
     */
    getEditReserveData: function(dataObj) {
      this.$axios
        .post(
          'private/get-edit-reserve',
          {
            reserveId: this.reserveId,
            arrivalDateTimeValue: this.$store.state.call.arrivalDateTimeValue,
          },
          {
            headers: {
              Authorization: this.$auth.$storage.getUniversal(
                '_token.auth0',
                true
              ),
            },
          }
        )
        .then((response) => {
          this.editReserveData = response.data;
          if (this.editReserveData.length !== 0) {
            if (this.editReserveData['reservation']['site_member_id']) {
              dataObj['editReserveSiteMemberId'] = this.editReserveData[
                'reservation'
              ]['site_member_id'];
            }
            // 支払い
            if (this.editReserveData['reservation']['card_flag'] === 0) {
              dataObj['paymentMethodValue'] = 2;
            } else {
              dataObj['paymentMethodValue'] = 1;
            }
            dataObj['reserveUpdatedAt'] = this.editReserveData['reservation'][
              'updated_at'
            ];
            // 割引
            dataObj['discountName'] = this.editReserveData['reservation'][
              'discount_name'
            ];
            dataObj['discountMoney'] = this.editReserveData['reservation'][
              'discount_money'
            ];
            // 予約確認時刻
            dataObj['willConfirmAt'] = this.editReserveData['reservation'][
              'will_confirm_at'
            ];
            // 予約時のサービスをセット
            var reserveServiceItem = this.editReserveData['serviceItem'];
            var serviceCourse = this.serviceData.course;
            var serviceOption = this.serviceData.option;
            var optionObj = [];
            reserveServiceItem.map(function(reserveService) {
              // コース
              serviceCourse.map(function(service) {
                if (
                  reserveService['service_id'] === service['id'] &&
                  service['possibleFlag'] === true
                ) {
                  dataObj['courseValue'] = service['id'];
                  dataObj['courseMoney'] = service['service_money'];
                  dataObj['courseName'] = service['name'];
                  dataObj['useCourseMinute'] = service['minute'];
                }
              });
              // オプション
              serviceOption.map(function(service) {
                if (reserveService['service_id'] === service['id']) {
                  optionObj.push(service['id']);
                }
              });
            });
            this.optionValue = optionObj;
            // 予約時のサイト会員サイト予約サービス
            var reserveServiceRequest = this.editReserveData['serviceRequest'];
            if (reserveServiceRequest.length !== 0) {
              Object.keys(reserveServiceRequest).forEach(function(key) {
                dataObj['siteReservationServiceDataLabel'][key] =
                  reserveServiceRequest[key];
              });
            }
            // 問診票サービス
            var medicalQuestionnaireRequest = this.editReserveData[
              'medicalQuestionnaireRequest'
            ];
            if (medicalQuestionnaireRequest.length !== 0) {
              if (
                dataObj['medicalQuestionnaireServiceDataLabel']['radio'] &&
                medicalQuestionnaireRequest['radio']
              ) {
                Object.keys(medicalQuestionnaireRequest['radio']).forEach(
                  function(key, val) {
                    dataObj['medicalQuestionnaireServiceDataLabel']['radio'][
                      key
                    ] = medicalQuestionnaireRequest['radio'][key];
                  }
                );
              }
              if (
                dataObj['medicalQuestionnaireServiceDataLabel']['checkbox'] &&
                medicalQuestionnaireRequest['checkbox']
              ) {
                Object.keys(medicalQuestionnaireRequest['checkbox']).forEach(
                  function(key) {
                    dataObj['medicalQuestionnaireServiceDataLabel']['checkbox'][
                      key
                    ] = medicalQuestionnaireRequest['checkbox'][key];
                  }
                );
              }
              if (
                dataObj['medicalQuestionnaireServiceDataLabel']['text'] &&
                medicalQuestionnaireRequest['text']
              ) {
                Object.keys(medicalQuestionnaireRequest['text']).forEach(
                  function(key) {
                    dataObj['medicalQuestionnaireServiceDataLabel']['text'][
                      key
                    ] = medicalQuestionnaireRequest['text'][key];
                  }
                );
              }
              if (
                this.editReserveData[
                  'medicalQuestionnaireRequestDisplayFlag'
                ] == true
              ) {
                dataObj['medicalQuestionnaireServiceAllDisplayFlag'] = true;
              }
            }
            // DSからの予約の場合カード支払いに手数料を表示する
            if (this.editReserveData.reservation.reservation_from_ds) {
              this.reservationFromDs = true;
              this.paymentMethodItems[1] =
                this.$t('credit_card') +
                `（` +
                this.$t('fee') +
                this.editReserveData.reservation.shop.card_fee +
                `）`;
              this.shopCardFee = this.editReserveData.reservation.shop.card_fee;
              this.cardFeeMoney = this.calculateCardFeeMoney;
            }
          }
          //this.getReserveSiteMemberPoint();
          this.getHotels();
        });
    },
    /**
     * 予約情報保存
     */
    postReserve: async function() {
      // validation
      if (this.shopHotelsErrorFlag || !this.reserveValidate()) {
        return;
      }

      /*
      if (this.totalMoney < 0) {
        return (this.reservePointErrorMessage = true);
      }
      */
      const talentNg = [];
      let useAddress = '';
      let useBuilding = '';
      let hotelId = '';
      let homeUseFlag = 0;
      let useRoomNumber = '';
      let subAreaValue = '';
      let asDesignateFlag = this.designateData
        ? this.designateData.as_designate_flag
          ? this.designateData.as_designate_flag
          : 0
        : 0;
      if (this.$store.state.siteMemberData.selectPlace === 'selected-home') {
        useAddress =
          this.$store.state.siteMemberData.home_city_text +
          ' ' +
          this.$store.state.siteMemberData.home_address;
        useBuilding = this.$store.state.siteMemberData.home_building;
        useRoomNumber = this.$store.state.siteMemberData.home_room_number;
        homeUseFlag = 1;
        subAreaValue = this.$store.state.siteMemberData.home_sub_area_id;
        talentNg.push(
          this.checkTalentNgItem(2, homeUseFlag, false) && homeUseFlag
        ); // 自宅NGか
        talentNg.push(this.checkTalentNgItem(3, useAddress)); // 住所NGか
        talentNg.push(this.checkTalentNgItem(4, useBuilding)); // 物件NGか
      } else if (
        this.$store.state.siteMemberData.selectPlace === 'selected-hotel'
      ) {
        if (
          this.selectedHotel &&
          Object.keys(this.selectedHotel).length > 0 &&
          this.selectedHotel.name !== this.hotelDefaultFeildText
        ) {
          useAddress = this.selectedHotel.address;
          useBuilding = this.selectedHotel.name;
          hotelId = this.selectedHotel.id;
        } else {
          useAddress = '';
          useBuilding = '';
          hotelId = '';
        }
        useRoomNumber = this.use_room_number;
        talentNg.push(this.checkTalentNgItem(3, useAddress)); // 住所NGか
        talentNg.push(this.checkTalentNgItem(4, useBuilding)); // 物件NGか
        // NGホテルなら処理を止める
        if (this.ngHotels.find((v) => v.id == this.selectedHotelId)) {
          this.$store.dispatch('dialog/reservationImpossibleMode', 5);
          this.reservationImpossible = true;
          return;
        }
      }
      let postSiteMemberSiteReservationServiceData = this
        .postSiteMemberSiteReservationService;
      let postMedicalQuestionnaireServiceData = this
        .postMedicalQuestionnaireService;

      let reservePointId = '';
      /*
      if (this.reservePointData.length > 0) {
        reservePointId = this.reservePointData[0].id;
      }
      */

      if (talentNg.filter((ng) => ng).length > 0) {
        this.$store.dispatch('dialog/reservationImpossibleMode', 2);
        this.reservationImpossible = true;
      } else if (this.$store.state.auth.loggedIn) {
        this.$store.dispatch('loading/save', true);
        let params = {
          reserveId: this.reserveId, // 予約ID
          arrivalDateTimeValue: this.$store.state.call.arrivalDateTimeValue, // 何時何分
          arrivalTimeValue: this.$store.state.call.arrivalTimeValue, // 何分後
          asDesignateFlag: asDesignateFlag, // 本指名扱い
          cardPaymentMoney: this.totalMoney, // カード支払い料金(総額を渡す)
          castAdditionalMoney: this.castData['cast_additional_money'], // キャスト追加料金
          castId: this.$store.state.call.nominateCast, // キャストID
          castFreeFlag: this.castFree ? 1 : 0, // キャストフリー
          designationMoney: this.designationMoney, // 指名料金
          designationName: this.designationName, // 指名名称
          courseMoney: this.courseMoney, // コース料金
          courseName: this.courseName, // コース名
          optionNames: this.optionLabel, // オプション名
          optionMoney: this.optionMoney, // オプション料金
          extensionName: this.extensionName, // 延長(スペシャルカード)
          extensionMoney: this.extensionMoney, // 延長(スペシャルカード)
          extensionMinute:
            this.extensionData && this.extensionData.minute
              ? this.extensionData.minute
              : 0, // 延長時間(スペシャルカード)
          paymentMethodValue: this.paymentMethodValue, // お支払い方法(カードor現金)
          reservationServiceItem: this.makeServiceData, // サービス
          siteMemberId: this.$store.state.siteMemberData.id, // 会員ID
          totalMoney: this.totalMoney, // 総額
          transportationMoney: this.transportationMoney, // 交通費
          useAddress: useAddress, // 住所
          useBuilding: useBuilding, // 物件
          useCourseMinute: this.useCourseMinute, // コース利用分数
          useRoomNumber: useRoomNumber, // 部屋番号
          areaValue: this.areaValue, // エリアID
          subAreaValue: subAreaValue, // サブエリアID
          hotelId: hotelId,
          homeUseFlag: homeUseFlag, // 自宅利用フラグ
          useSpecialCardId: this.useSpecialCardId, // スペシャルカードID
          email: `${this.$store.state.auth.user.email}`,
          siteMemberTel: this.$store.state.siteMemberData.tel,
          corporationId: this.castData.shop.corporation_id,
          siteReservationServiceLabel:
            postSiteMemberSiteReservationServiceData['memo'],
          siteReservationServiceRequests:
            postSiteMemberSiteReservationServiceData['requests'],
          medicalQuestionnaireServiceLabel:
            postMedicalQuestionnaireServiceData['memo'],
          medicalQuestionnaireServiceRequests:
            postMedicalQuestionnaireServiceData['requests'],
          talentId: this.castData.talent_id,
          shopId: this.castData.shop.id,
          willConfirmAt: this.willConfirmAt,
          discountName: this.discountName,
          discountMoney: this.discountMoney,
          reservePoint: this.reservePoint,
          reservePointId: reservePointId,
          cardFeeMoney: this.cardFeeMoney,
          reserveUpdatedAt: this.reserveUpdatedAt,
        };
        await this.$axios
          .post('private/save-reservation', params, {
            headers: {
              Authorization: this.$auth.$storage.getUniversal(
                '_token.auth0',
                true
              ),
            },
          })
          .then(async (response) => {
            await this.$store.dispatch('loading/save', false);
            // 通知情報を更新
            this.getAjaxReserveNotification();
            // ▼アフィリエイト▼
            // コンバージョン実行後に完了画面へ遷移
            await this.affiliateConversion(response.data);
            this.$router.push(
              `/${this.$i18n.locale}/call/step3?id=${response.data.id}`
            );
            // ▲アフィリエイト▲
          })
          .catch(async (error) => {
            console.log('error:'.error);
            const modal0_keyword = [
              'ngNewCustomer',
              'corporationCustomerNgTalent',
              'talentNgCustomers',
              'overReservationLimit',
              'designateOnly',
              'freeDesignate',
              'ngTel',
              'customerCharacteristicsAboutNotHome',
              'customerEvaluationLine',
            ];
            const modal2_keyword = [
              'customerCharacteristicsAboutHome',
              'ngHomeBusinessTrip',
              'ngNewCustomerHomeBusinessTrip',
              'conditionalHomeNg',
              'subAreaNg',
              'HotelNg',
            ];
            const modal3_keyword = ['tightSchedule'];
            const modal4_keyword = ['ngShop'];
            await this.$store.dispatch('loading/save', false);
            if (
              _.intersection(error.response.data.keyword, modal4_keyword).length
            ) {
              this.$store.dispatch('dialog/reservationImpossibleMode', 4); // ご指定の店舗は
              this.reservationImpossible = true;
            } else if (
              _.intersection(error.response.data.keyword, modal0_keyword).length
            ) {
              this.$store.dispatch('dialog/reservationImpossibleMode', 0); // キャストの経験や申告などを元に
              this.reservationImpossible = true;
            } else if (
              _.intersection(error.response.data.keyword, modal3_keyword).length
            ) {
              this.$store.dispatch('dialog/reservationImpossibleMode', 3); // ご指定の日時、コースでは
              this.reservationImpossible = true;
            } else if (error.response.data.keyword === 'reservationEdit') {
              this.$router.push(
                '/reserve/' + this.reserveId + '?reservationEdit=true'
              );
            } else if (
              _.intersection(error.response.data.keyword, modal2_keyword).length
            ) {
              this.$store.dispatch('dialog/reservationImpossibleMode', 2); // ご指定の場所では
              this.reservationImpossible = true;
            } else {
              this.errorDialog = true;
            }
          })
          .finally(() => {
            // Store の初期化
            if (!this.reservationImpossible && !this.errorDialog) {
              this.resetStore();
            }
          });
      }
    },
    /**
     * 予約可能かチェック
     */
    checkResrvable: function() {
      const accessToken = this.$auth.$storage.getUniversal(
        '_token.auth0',
        true
      );
      let notTravelTime = false;
      if (this.reserveId) {
        notTravelTime = true;
      }
      this.$axios
        .post(
          'private/cast-check-resrvable',
          {
            email: `${this.$store.state.auth.user.email}`,
            arrivalTimeValue: this.$store.state.call.arrivalTimeValue,
            arrivalDateTimeValue: this.$store.state.call.arrivalDateTimeValue,
            areaValue: this.areaValue,
            subAreaValue:
              this.$store.state.siteMemberData.selectPlace === 'selected-home'
                ? this.$store.state.siteMemberData.home_sub_area_id
                : '',
            noUseFlag: this.$store.state.siteMemberData.noUseFlag,
            hotelId: this.$store.state.siteMemberData.selectedHotelId,
            homeBusinessTrip:
              this.$store.state.siteMemberData.selectPlace === 'selected-home'
                ? 1
                : 0, // 1: 自宅,  0: ホテル
            siteMemberTel: this.$store.state.siteMemberData.tel,
            castId: this.$store.state.call.nominateCast,
            castFreeFlag: this.castFree ? 1 : 0, // キャストフリー
            corporationId: this.$store.state.call.selectedCorporationId,
            notTravelTime: notTravelTime,
            reserveId: this.reserveId,
          },
          {
            headers: {
              Authorization: `${accessToken}`,
            },
          }
        )
        .then((res) => {
          // 予約不可キャストの場合、表示ダイアログ出し分けのフラグ立てる
          if (res.data.castData && res.data.ngCharacteristics === 0) {
            // キャスト都合NG判定（エリア以外）
            this.$store.dispatch('dialog/reservationImpossibleMode', 0);
            this.reservationImpossible = true;
          } else if (
            res.data.ngCharacteristics !== 0 &&
            res.data.ngShop === 0
          ) {
            // 利用店舗NG
            this.$store.dispatch('dialog/reservationImpossibleMode', 4);
            this.reservationImpossible = true;
          } else if (!res.data.castData) {
            // Tokyo Escort OTOME予約不可キャスト
            this.$store.dispatch('dialog/reservationImpossibleMode', 1);
            this.reservationImpossible = true;
          } else if (
            res.data.ngCharacteristics !== 0 &&
            res.data.ngArea === 0
          ) {
            // キャスト都合NG判定（エリアのみ）
            this.$store.dispatch('dialog/reservationImpossibleMode', 2);
            this.reservationImpossible = true;
          }
        })
        .catch((error) => {});
    },
    checkCastNgCourse: function(value) {
      return (this.castData.ng_courses || '').split(',').includes(value);
    },
    checkCastNgOption: function(value) {
      return (this.castData.ng_options || '').split(',').includes(value);
    },
    giveBackPointOptionName: function() {
      var optionNames = this.giveBackPointData.option.map(
        (item) => item['serviceName']
      );
      return optionNames.join('/');
    },
    giveBackPointOption: function(id) {
      var optionData = this.giveBackPointData.option.find((item) => {
        return item['serviceId'] == id;
      });
      return optionData ? optionData.point.toLocaleString() : 0;
    },
    /**
     * タレントNG項目チェック
     * @param   itemType 項目種別（0:オプション、1:コース、2:自宅、3:住所、4:物件）
     * @param   useWord ワードチェックを行うか（falseの場合は項目が1件でもあればNGとなる）
     * @param   value   値
     * @return  boolean 結果（=true:NG）
     */
    checkTalentNgItem: function(itemType, value, useWord = true) {
      const { talent_ng_site_members } = this.$store.state.siteMemberData;
      const talentId = this.castData.talent_id;
      const shopId = this.castData.shop_id;
      const itemFilter = (item) => {
        const match =
          item.talent_id === talentId && item.item_type === itemType;
        return itemType === 0 || itemType === 1
          ? item.shop_id === shopId && match // 店舗も考慮する（コース、オプション）
          : match;
      };
      const wordFilter = (item) =>
        item.word.split(',').filter((word) => {
          return itemType === 0 || itemType === 1
            ? value.toString() === word // 完全一致（コース、オプション）
            : value.toString().indexOf(word) >= 0; // 部分一致（自宅、住所、物件）
        }).length > 0;
      return useWord
        ? talent_ng_site_members.filter(itemFilter).filter(wordFilter).length >
            0
        : talent_ng_site_members.filter(itemFilter).length > 0;
    },
    pointValidate: function() {
      this.specifiedPoint = this.specifiedPoint.replace(/\D/g, '');
      this.specifiedPoint = this.specifiedPoint.replace(/^0+/, '');
      var availablePoint = this.availablePoint;
      if (!this.specifiedPoint) {
        this.specifiedPoint = 0;
        this.reservePoint = 0;
      } else if (this.specifiedPoint > availablePoint) {
        this.specifiedPoint = availablePoint;
        this.reservePoint = availablePoint;
      } else {
        var specifiedPoint = this.specifiedPoint;
        this.reservePoint = specifiedPoint;
      }
    },
    roomNumberValidate: function() {
      this.$nextTick(() => {
        this.use_room_number = this.use_room_number.replace(
          /[^a-zA-Z0-9\-]+$/g,
          ''
        );
      });
    },
    changeMedicalQuestionnaireServiceAllDisplayFlag: function(serviceName) {
      if (serviceName == this.$t('text_c2tb')) {
        this.medicalQuestionnaireServiceAllDisplayFlag = true;
        this.getLatestMedicalQuestionnaireServiceRequests();
      } else {
        this.medicalQuestionnaireServiceAllDisplayFlag = false;
      }
    },
    ngAlert: function(state, message) {
      this.ngAlertMessage = message;
      this.ngAlertState = state;
    },
    /*
     * 同店舗の最新問診票サービス要望を取得
     */
    getLatestMedicalQuestionnaireServiceRequests: function() {
      if (!this.medicalQuestionnaireServiceRequestsGetFlag) {
        this.$axios
          .post(
            'private/get-latest-medical-questionnaire-requests',
            {
              siteMemberId: this.$store.state.siteMemberData.id,
              shopId: this.castData.shop.id,
            },
            {
              headers: {
                Authorization: this.$auth.$storage.getUniversal(
                  '_token.auth0',
                  true
                ),
              },
            }
          )
          .then((response) => {
            this.medicalQuestionnaireServiceRequestsLatest = response.data;
            this.medicalQuestionnaireServiceRequestsGetFlag = true;
          });
      }
    },
    openDialogSetLatestMedicalQuestionnaireServiceRequests: function() {
      //最新問診票サービス要望がない場合にモーダルを開く
      if (this.medicalQuestionnaireServiceRequestsLatest === '')
        this.$refs.medicalQuestionnaireServiceRequestsNoSetDialog.dialogFlag = true;
    },
    /*
     * 同店舗の最新問診票サービス要望のセット切り替え
     */
    toggleSetLatestMedicalQuestionnaireServiceRequests: function() {
      if (this.medicalQuestionnaireServiceRequestsSetFlag) {
        this.setLatestMedicalQuestionnaireServiceRequests();
      } else {
        this.unSetLatestMedicalQuestionnaireServiceRequests();
      }
    },
    /*
     * 同店舗の最新問診票サービス要望をセットはずし
     */
    unSetLatestMedicalQuestionnaireServiceRequests: function() {
      self = this;
      //チェックボックス
      Object.keys(this.medicalQuestionnaireServiceDataLabel.checkbox).forEach(
        function(setId) {
          self.medicalQuestionnaireServiceDataLabel.checkbox[setId].splice(0);
        }
      );
      //ラジオボタン
      Object.keys(this.medicalQuestionnaireServiceDataLabel.radio).forEach(
        function(setId) {
          //お好み指定のラジオボタンは解除対象から除外する
          let unset = true;
          Object.values(
            self.medicalQuestionnaireServiceData.service[setId]
          ).forEach(function(value) {
            if (value.name === this.$t('text_c2tb')) unset = false;
          });
          if (unset)
            self.medicalQuestionnaireServiceDataLabel.radio[setId] = '';
        }
      );
      //テキストフィールド
      Object.keys(this.medicalQuestionnaireServiceDataLabel.text).forEach(
        function(setId) {
          self.medicalQuestionnaireServiceDataLabel.text[setId] = '';
        }
      );
      this.medicalQuestionnaireServiceRequestsSetFlag = false;
    },
    /*
     * 同店舗の最新問診票サービス要望をセット
     */
    setLatestMedicalQuestionnaireServiceRequests: function() {
      self = this;
      //チェックボックス
      Object.keys(this.medicalQuestionnaireServiceDataLabel.checkbox).forEach(
        function(setId) {
          let requests = self.medicalQuestionnaireServiceRequestsLatest[setId]
            ? self.medicalQuestionnaireServiceRequestsLatest[setId]
            : null;
          if (requests) {
            Object.keys(requests).forEach(function(key) {
              self.medicalQuestionnaireServiceDataLabel.checkbox[setId].push(
                requests[key]['medical_questionnaire_service_id']
              );
            });
          }
        }
      );
      //ラジオボタン
      Object.keys(this.medicalQuestionnaireServiceDataLabel.radio).forEach(
        function(setId) {
          let requests = self.medicalQuestionnaireServiceRequestsLatest[setId]
            ? self.medicalQuestionnaireServiceRequestsLatest[setId]
            : null;
          if (requests) {
            Object.keys(requests).forEach(function(key) {
              self.medicalQuestionnaireServiceDataLabel.radio[setId] =
                requests[key]['medical_questionnaire_service_id'];
            });
          }
        }
      );
      //テキストフィールド
      Object.keys(this.medicalQuestionnaireServiceDataLabel.text).forEach(
        function(setId) {
          let requests = self.medicalQuestionnaireServiceRequestsLatest[setId]
            ? self.medicalQuestionnaireServiceRequestsLatest[setId]
            : null;
          if (requests) {
            Object.keys(requests).forEach(function(key) {
              self.medicalQuestionnaireServiceDataLabel.text[setId] =
                requests[key]['service_info'];
            });
          }
        }
      );
      this.medicalQuestionnaireServiceRequestsSetFlag = true;
    },
    /*
    pointDisplay(point) {
      if (!point) return 0;
      return parseInt(point).toLocaleString();
    },
    */
    endDateDisplayFlag(endDate) {
      if (
        this.$moment().format('YYYY-MM-DD') >
        this.$moment(endDate)
          .subtract(1, 'months')
          .format('YYYY-MM-DD')
      ) {
        return true;
      } else {
        return false;
      }
    },
    /*
    concurrentUseText(flag) {
      if (flag == 0) {
        return `※合算不可（一番高く設定されているポイントが還元されます）`;
      } else {
        return `※合算可（他の「合算可」のポイントと合算されます）`;
      }
    },
    */
    reserveValidate: function() {
      // エラーメッセージ初期化
      this.reserveValidateErrorMessages = [];

      // コース
      if (this.courseValue === '') {
        this.reserveValidateErrorMessages.push(this.$t('text_038v'));
      }

      // お支払い方法
      if (this.paymentMethodValue === '') {
        this.reserveValidateErrorMessages.push(this.$t('text_rjn7'));
      }

      // サービス利用規約
      if (!this.useTermsCheck) {
        this.reserveValidateErrorMessages.push(this.$t('text_uii9'));
      }

      // ポイント関連
      // カード決済かつ総額が0
      if (this.paymentMethodValue === 1 && Number(this.totalMoney) === 0) {
        this.reserveValidateErrorMessages.push(this.$t('text_pm9n'));
      }

      // 現金決済かつ総額が0でないかつ1000P単位でない
      if (
        this.paymentMethodValue === 2 &&
        Number(this.totalMoney) !== 0 &&
        Number(this.reservePoint) % 1000 !== 0
      ) {
        this.reserveValidateErrorMessages.push(
          '現金決済の場合は1000P単位でご使用可能です。'
        );
      }

      // 部屋番号
      if (!(!this.use_room_number || this.use_room_number.replace(/-/g, ''))) {
        this.reserveValidateErrorMessages.push(this.$t('text_pq0r'));
      }

      if (!_.isEmpty(this.reserveValidateErrorMessages)) {
        this.reserveValidateError = true;
        return false;
      }

      return true;
    },
    toCastScheduleForChangeReserve: function(urlHash, reserveId) {
      this.$store.dispatch('call/commitChangeReservationId', reserveId);
      this.$store.dispatch(
        'call/commitChangeReservationCourseMinute',
        this.useCourseMinute
      );
      this.$router.push(
        `/${this.$i18n.locale}/cast/${urlHash}/?schedule=true`
      );
    },
    // ▼アフィリエイト▼
    // 成果計測 アフィリエイトアド用
    affiliateConversion: function (data) {
      return new Promise((resolve, reject) => {
        var uqid = '934af76e11f23b7e';
        var gid = '2';
        var uid = data.id; //予約ID
        var uid2 = '';
        var af = '';
        var pid = '';
        var amount = this.totalMoney; //総額
        var a = document.createElement('script');
        a.src = '//ac.yumeoto.net/ac/action_js.php';
        a.id = 'afadaction-' + Date.now();
        a.addEventListener('load', function () {
          new fpcAction(a.id).groupAction(
            gid,
            af,
            [uid, uid2],
            pid,
            amount,
            uqid
          );
        });
        document.head.appendChild(a);
        resolve();
      });
    },
    // ▲アフィリエイト▲
  },
};
</script>

<style lang="scss" scoped>
.usr-call-step2-cond__header {
  color: var(--read-font-color-primary);
  display: inline-block;
  font-weight: bold;
}
.usr-call-step2-cond_reserve_date {
  font-size: var(--read-font-size-l);
  color: var(--read-font-color-sub);
  display: inline-block;
  font-weight: bold;
}
.usr-call-step2-cond_change-day {
  color: #666;
  font-size: var(--read-font-size-s);
  font-weight: bold;
  color: var(--read-font-color-sub);
  box-shadow: 0 1.5px 1.5px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 2px 1px -2px rgba(0, 0, 0, 0.2) !important;
}
::v-deep .v-btn.usr-call-step2-cond_change-day {
  padding: 5px !important;
}
.usr-call-step2-cond_course__btn {
  width: 100%;
  color: #666;
  font-size: var(--read-font-size-xs);
  font-weight: bold;
  color: var(--read-font-color-sub);
  box-shadow: 0 1.5px 1.5px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 2px 1px -2px rgba(0, 0, 0, 0.2) !important;
}

.usr-call-step2-cond_course__btn.disabled {
  color: #f5f5f5;
}

.v-btn--active {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 2px 1px -2px rgba(0, 0, 0, 0.2) !important;
  border-color: var(--main-color) !important;
  color: var(--main-color) !important;
}
.v-btn--active li {
  color: var(--main-color) !important;
}
.call-cardImg {
  position: absolute;
  right: 0;
  top: 5px;
  width: 100px;
}
.call-point-input {
  margin-top: 35px;
  width: 100px;
  border-radius: 3px;
  border: 2px solid #ddd;
  box-sizing: border-box;
}

/* 予約ナビゲーション */
.cv-reserve-nav {
  padding-right: var(--c-lr-pd);
  padding-left: var(--c-lr-pd);
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 500px;
  background: rgba(240, 243, 244, 0.95);
  z-index: 3;
  box-shadow: 0 -0.05rem 5px #bebebe;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  align-items: center;
  -webkit-align-items: center;
}

/* ポイント */
.point-setting {
  max-width: 500px;
  background: #ffe5eb;
  border-radius: 10px;
  .point-set-code {
    font-size: 10px;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    background: var(--sub-color);
    font-weight: bold;
    width: 40px;
  }
  .point-set-description {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
  }
  .point-set-code-icon-box {
    width: 40px;
    height: 36px;
    position: relative;
    .point-set-code-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .icon-point {
    width: 36px;
    height: 36px;
  }
  .icon-point-small {
    width: 11px;
    height: 11px;
  }
  .point {
    font-size: 36px;
    font-weight: bold;
    color: var(--main-color);
    min-width: 106px;
    text-align: center;
  }
  .point-option {
    font-weight: bold;
    color: var(--main-color);
  }
  .point-present {
    position: relative;
    font-size: 12px;
    font-weight: bold;
    color: var(--main-color);
    .icon-radiation {
      position: absolute;
      top: -12px;
      right: 8px;
    }
  }
  .point-present-2 {
    position: relative;
    font-size: 16px;
    font-weight: bold;
    color: var(--main-color);
    .icon-radiation {
      position: absolute;
      top: -14px;
      right: -10px;
    }
  }
  .target-description {
    background: var(--sub-color);
    font-size: 10px;
    font-weight: bold;
    color: #fff;
  }
  .point-expiry-date {
    background: #fff9fb;
    font-size: 10px;
    font-weight: bold;
    color: #333333;
  }
  .point-concurrent-use {
    font-size: 10px;
    font-weight: bold;
    color: var(--sub-color);
  }
}
.point-notice {
  border: 1px solid var(--main-color);
  font-size: 12px;
  font-weight: bold;
  color: #333333;
}
.reserve-btn-disabled {
  background-color: rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
</style>

<style scoped>
.point-readonly >>> .theme--light.v-label {
  color: rgba(0, 0, 0, 0.38) !important;
}
</style>
